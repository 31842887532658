import React from "react";
import styled from "styled-components";
import WelcomesBesaz from "../Assets/Images/welcome Besaz.png";
import MainLayout from "./Layout/MainLayout";
import BesazComponentImg from "../Assets/Images/BesazComponentImg.png";
import MacAnalysis from "../Assets/Images/MacAnalysis .png";
import BesazComponent from "../Components/Besaz/BesazComponent";
import { SlideBar } from "../Components/Download App/SlideBar";
import mapbesaz from "../Assets/Images/MapBesaz.png";
import ContainerWebComponent from "../Components/Container/ContainerWebComponent";
const Style = styled.div`
  height: 100%;
  z-index: 100;
  width: 100%;
  background: #252526;
  color: #ffffff;
  .container {
    .linktheme {
      color: var(--text-1) !important;
      background: var(--text-8) !important;
    }
  }
  .titlcomponents {
    font-size: 40px;
    position: relative;
    display: flex;
    
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    line-height: 68px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
  .header-form {
    display: grid;
    grid-template-columns:
      6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
      6.09%;
    grid-column-gap: 1.56%;
    .header-form-title {
      margin-bottom: 80px;
      margin-top: 200px;
      
      font-weight: 500;
      font-size: var(--fs--48-30-24);
      line-height: 81px;
      letter-spacing: 0px;
      text-align: center;
      grid-column: 5/11;
    }
    .header-form-desc {
      grid-column: 2/14;
      
      font-size: var(--fs--24-20-18);
      font-weight: 400;
      letter-spacing: 0px;
      text-align: right;
    }
  }
  .besazpanelpod {
    display: grid;
    grid-template-columns:
      6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
      6.09%;
    grid-column-gap: 1.56%;
    align-items: center;
    background: #201f1e;
    justify-content: center;
    .besazpanelpodcontent {
      grid-column: 2/14;
      .titlebesazpanelpod {
        
        font-size: 32px;
        font-weight: 500;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: right;
        margin-bottom: 24px;
      }
      .descbesazpanelpod {
        
        font-size: var(--fs--28-18-16);
        font-weight: 400;
        line-height: 47px;
        letter-spacing: 0em;
        text-align: right;
      }
    }
    .titlcomponents {
      grid-column: 4/12;
    }
    .besazpanelpodimg {
      grid-column: 5/11;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .sidebarbesaz {
    margin-top: 80px !important;
    margin-bottom: 100px !important;
  }
  @media screen and (max-width: 1000px) {
    display: flex;
    width: calc(100% - 60px);
    flex-direction: column;
    margin: 0 auto 72px;
  }
  @media screen and (max-width: 1250px) {
    .titlcomponents {
      font-size: 32px !important;
    }
    .titlebesazpanelpod {
      
      font-size: 26px !important;
    }
    .title {
      line-height: 50px !important;
      margin: 0 !important;
    }
    .desc {
      line-height: 33px !important;
      margin-bottom: 24px !important;
    }
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    /* .container {
      display: flex;
      width: calc(100% - 60px);
      .content {
        display: flex;
        header {
          width: 50%;
        }
      }
    } */
  }
  @media screen and (max-width: 850px) {
    .titlcomponents {
      font-size: 24px !important;
    }
    .titlebesazpanelpod {
      
      font-size: 18px !important;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
    z-index: 100;
    background: #252526;
    color: #ffffff;
    .titlebesazpanelpod {
      
      font-size: 20px;
    }
    .sidebarbesaz {
      margin-top: 39px !important;
      margin-bottom: 39px !important;
    }
    .title {
      
      font-weight: 500 !important;
      line-height: 47px !important;
      letter-spacing: 0em;
      text-align: right;
    }
    .text {
      
      font-weight: 400 !important;
      line-height: 34px !important;
      letter-spacing: 0em;
      text-align: right;
    }
    /* .container {
      display: flex;
      width: calc(100% - 60px) !important;
      flex-direction: column !important;
      .content {
        width: 100% !important;
      }
      .imagewelcome {
        max-width: 100%;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        header {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 20px;
          margin-bottom: 8px;
        }
        .btns {
          display: flex;
          flex-direction: column;
          width: 100%;
          font-weight: 500 !important;
          line-height: 34px !important;
        }
        .btna {
          font-weight: 500 !important;
          line-height: 34px !important;
          height: 58px !important;
          width: 100% !important;
          background: #64b246;

          border-radius: 15px;
          border: none;
          cursor: pointer;
          margin-bottom: 20px;
        }
        .btnb {
          
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 31px;
          height: 58px !important;
          width: 100% !important;
          color: #ffffff;
          background: none;
          border-radius: 15px;
          border: 2px solid #edebe9;
          cursor: pointer;
        }
      }
    } */
    .propertise {
      display: flex;
      flex-direction: column;
    }
    .header-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% - 60px);
      margin: 0 auto 50px !important;
      .header-form-title {
        margin-top: 91px !important;
        margin-bottom: 16px !important;
        font-size: var(--fs--48-30-24);
        font-weight: 500 !important;
        letter-spacing: 0px;
        text-align: center;
      }
      .header-form-desc {
        font-size: var(--fs--24-20-18);
        
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: right;
      }
    }
    .besazpanelpod {
      display: grid;
      grid-template-columns:
        6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
        6.09%;
      grid-column-gap: 1.56%;
      align-items: center;
      background: #201f1e;
      justify-content: center;
      .besazpanelpodcontent {
        grid-column: 2/14;
        .titlebesazpanelpod {
          
          font-size: 32px;
          font-weight: 500;
          line-height: 54px;
          letter-spacing: 0em;
          text-align: right;
          margin-bottom: 8px !important;
        }
        .descbesazpanelpod {
          
          font-size: var(--fs--28-18-16);
          font-weight: 400;
          line-height: 47px;
          letter-spacing: 0em;
          text-align: right;
        }
      }
      .titlcomponents {
        grid-column: 4/12;
      }
      .besazpanelpodimg {
        grid-column: 5/11;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`;
const Form = styled.form`
  margin-top: 80px;
  margin-bottom: 140px !important;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  .filds {
    grid-column: 3/13;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 64px;
  }
  .text-send {
    height: 182px !important;
    width: 100% !important;
    input {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .filds-footer {
    grid-column: 3/13;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    button {
      background: #64b246;
      margin-top: 16px;
      border-radius: 15px;
      border: none !important;
      width: 30%;
      height: 58px;
      color: #ffffff;
      font-size: var(--fs--24-20-20);
    }
    desc {
      display: flex;
      font-size: var(--fs--24-16-0) !important;
      p {
        color: #fd502e;
        font-size: var(--fs--24-16-0) !important;
      }
    }
  }
  .fild-input {
    width: 49%;
    p {
      display: inline;
      font-size: var(--fs--24-20-18);
    }
    input {
      margin-top: 3px;
      background: none;
      border: 1px solid #c8c6c4;
      border-radius: 15px;
      height: 70px;
      width: 100%;
    }
  }
  @media screen and (max-width: 850px) {
    display: flex !important;
    width: calc(100% - 60px) !important;
    flex-direction: column;
    margin: 100px auto 96px !important;
    .filds {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 32px !important ;
    }
    .text-send {
      height: 140px !important;
      margin-bottom: 32px !important ;
      width: 100% !important;
      input {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .filds-footer {
      grid-column: 3/13;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      button {
        border-radius: 15px;
        width: 50%;
        height: 58px;
        color: #ffffff;
        font-size: var(--fs--24-20-20);
      }
    }
    .fild-input {
      width: 100% !important;

      p {
        display: inline;
        font-size: var(--fs--24-20-18);
      }
      input {
        margin-top: 3px;
        background: none;
        border: 1px solid #c8c6c4;
        border-radius: 15px;
        height: 70px;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    display: flex !important;
    width: calc(100% - 60px) !important;
    flex-direction: column;
    margin: 40px auto 0 !important;
    .filds {
      display: flex;
      flex-direction: column !important;
      justify-content: space-between;
    }
    .text-send {
      height: 130px !important;
      margin-bottom: 32px !important ;
      width: 100% !important;
      input {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .filds-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      button {
        border-radius: 15px;
        width: 100% !important ;
        height: 58px;
        color: #ffffff;
        font-size: var(--fs--24-20-20);
      }
    }
    .fild-input {
      margin-bottom: 24px !important ;
      width: 100% !important;
      p {
        display: inline;
        font-size: var(--fs--24-20-18);
      }
      input {
        margin-top: 3px;
        background: none;
        border: 1px solid #c8c6c4;
        border-radius: 15px;
        height: 70px;
        width: 100%;
      }
    }
  }
`;
const MapBesaz = styled.div`
  width: 100%;
  background-image: url("../Assets/Images/MapBesaz.png");
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  align-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: auto;
  }
  .title-map-desc {
    grid-column: 3/7;
    margin-bottom: 24px;
    
    font-size: 32px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: right;
  }
  .desc-map {
    grid-column: 3/7;
    margin-bottom: 56px;
    
    font-size: var(--fs--28-18-16);
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: right;
  }
  .title-map {
    margin-bottom: 80px;
    margin-top: 200px;
    
    font-weight: 500;
    font-size: var(--fs--48-30-24);
    line-height: 81px;
    letter-spacing: 0px;
    text-align: center;
    grid-column: 6/10;
  }
  button {
    background: #64b246;
    grid-column: 4/7;
    border-radius: 15px;
    border: none !important;
    height: 58px;
    color: #ffffff;
    margin-bottom: 207px;
    font-size: var(--fs--24-20-20);
  }
  @media screen and (max-width: 1000px) {
    .title-map-desc {
      grid-column: 2/8;
      margin-bottom: 8px;
      
      font-size: 32px;
      font-weight: 500;
      line-height: 54px;
      letter-spacing: 0em;
      text-align: right;
    }
    .desc-map {
      grid-column: 2/8;
      margin-bottom: 24px;
      
      font-size: var(--fs--28-18-16);
      font-weight: 400;
      line-height: 47px;
      letter-spacing: 0em;
      text-align: right;
    }
    .title-map {
      margin-bottom: 80px;
      margin-top: 200px;
      
      font-weight: 500;
      font-size: var(--fs--48-30-24);
      line-height: 81px;
      letter-spacing: 0px;
      text-align: center;
      grid-column: 4/12;
    }
    button {
      background: #64b246;
      grid-column: 3/7;
      border-radius: 15px;
      border: none !important;
      height: 58px;
      color: #ffffff;
      margin-bottom: 207px;
      font-size: var(--fs--24-20-20);
    }
  }
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    .title-map {
      margin-bottom: 40px !important;
      margin-top: 118px !important;
    }
    .title-map-desc {
      margin-bottom: 8px !important;
      
      width: 90%;
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: right;
    }
    .desc-map {
      margin-bottom: 40px !important;
      width: 90%;
      
      font-size: 18px !important;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: right;
    }
    button {
      width: 50%;
      margin-bottom: 100px !important;
    }
  }
`;

export default function Besaz(props) {
  if (window.innerWidth > 1000) {
    return (
      <MainLayout {...props}>
        <Style>
          <ContainerWebComponent
            {...props}
            className="container"
            title="بساز"
            text="مدیریت آسان پادکست ها با مدیریت پادکده"
            desc="
                تمام ابزارهای لازم برای رشد و موفقیت پادکست را در اختیار شما
                می‌گذاریم
             "
            img={WelcomesBesaz}
            linkone="درخواست مشاوره"
            linktwo="چرا پادکده؟"
          />
          <p className="titlcomponents" style={{ margin: "0 auto 64px" }}>
            ما روند کار را برای ساختن یک پادکست موفق ساده کرده‌ایم
          </p>
          <BesazComponent
            detailleft={true}
            imgright={true}
            img={BesazComponentImg}
            title="نامحدود و رایگان"
            desc="مهم نیست چقدر محتوا تولید می‌کنید یا چقدر محبوب هستید و چه میزان شنونده دارید، در پادکده میزبانی برای شما رایگان است. "
          />
          <BesazComponent
            detailright={true}
            imgleft={true}
            img={BesazComponentImg}
            title="مدیریت آسان پائکست ها"
            desc="رابط کاربری ساده و قدرتمند پنل پادکستر، برای مدیریت یک یا چند پادکست طراحی شده است. تمام آنچه برای مدیریت پادکست خود نیاز دارید در پادکده برای شما فراهم شده تا پادکست خود را بدون استرس و بهم ریختگی منتشر کنید."
          />
          <BesazComponent
            detailleft={true}
            imgright={true}
            img={BesazComponentImg}
            title={"انتشار آسان"}
            desc={
              "با یک بار آپلود اپیزودها در پادکده و از طریقی فیدی که ما در اختیارتان می‌گذاریم، می‌توانید پادکست خود را در تمامی پلتفرم‌های پخش پادکست منتشر کنید."
            }
          />
          <BesazComponent
            detailright={true}
            imgleft={true}
            img={BesazComponentImg}
            title={"آمار و ارقان کاربردی و قابل فهم"}
            desc={
              "آماری که ما در اختیارتان می‌گذاریم شامل: روند دانلود، زمان‌های اوج دانلود، موقعیت جغرافیایی شنوندگان و نوع دستگاه‌هایی که در آن شنیده شده‌اید. با این آمار و ارقام نبض پادکست خود را در دست بگیرید و عملکرد آن را به صورت مداوم کنترل کنید و بهبود ببخشید. "
            }
          />
          <div className="besazpanelpod">
            <p className="titlcomponents" style={{ margin: "125px auto 80px" }}>
              پنل پادکستر پادکده، یک تصویر کامل از عملکرد پادکست شما
            </p>
            <div className="besazpanelpodimg">
              <img src={MacAnalysis} alt={"MacAnalysis"} />
            </div>
            <div className="besazpanelpodcontent">
              <p className="titlebesazpanelpod">برقراری ارتباط با شنوندگان</p>
              <p className="descbesazpanelpod">
                ارتباط شما با شنوندگان‌تان از مهمترین عوامل افزایش مخاطب است. ما
                این کار را برای شما آسان کرده‌ایم. کامنت‌های اپیزودهای مختلف
                پادکست خود را به صورت کاملا دسته‌بندی شده ببینید و پاسخ دهید.
              </p>
              <SlideBar className={"sidebarbesaz"} />
            </div>
          </div>
          <div className="header-form">
            <p className="header-form-title">ارتباط با ما</p>
            <p className="header-form-desc">
              مهم نیست تازه کار هستید، یا مدت زمان زیادی است پادکست درست
              می‌کنید، ما معتقدیم، محتوای شما لایق شنیده شدن است و امکانات ضروری
              ساخت پادکست را با بهترین کیفیت برای شما فراهم می‌کنیم. می‌توانید
              از طریق فرم زیر سوالات خود را با مشاوران ما در میان بگذارید.
            </p>
          </div>
          <Form>
            <div className="filds">
              <div className="fild-input">
                <p>نام و نام خانوادگی *</p>

                <input />
              </div>
              <div className="fild-input">
                <p>آدرس ایمیل *</p>
                <input />
              </div>
            </div>
            <div className="filds">
              <div className="fild-input">
                <p>شماره تلفن همراه *</p>
                <input />
              </div>
              <div className="fild-input">
                <p>نام پادکست</p>
                <input />
              </div>
            </div>
            <div className="filds">
              <div className="fild-input  text-send ">
                <p>توضیحات</p>
                <input />
              </div>
            </div>
            <div className="filds-footer">
              <button>ثبت درخواست</button>
            </div>
          </Form>
          <MapBesaz>
            <img src={mapbesaz} alt={"mapbesaaz"} />
            <p className="title-map">مهاجرت به پادکده</p>
            <p className="title-map-desc">پادکست دارید؟</p>
            <p className="desc-map">
              با چند کلیک به پادکده مهاجرت کنید و از خدمات نامحدود و رایگان ما
              استفاده کنید. فرآیند تغییر بسیار ساده و آسان است. داده‌های شما به
              هیچ وجه از دست نمی‌روند و تغییری نخواهند کرد. پادکست شما به محض
              انتقال در دسترس شنوندگان خواهد بود.
            </p>
            <button> مهاجرت به پادکده</button>
          </MapBesaz>
        </Style>
      </MainLayout>
    );
  } else {
    return (
      <MainLayout {...props}>
        <Style>
          <ContainerWebComponent
            {...props}
            className="container"
            title="بساز"
            text="مدیریت آسان پادکست ها با مدیریت پادکده"
            desc="
                تمام ابزارهای لازم برای رشد و موفقیت پادکست را در اختیار شما
                می‌گذاریم
             "
            img={WelcomesBesaz}
            linkone="درخواست مشاوره"
            linktwo="چرا پادکده؟"
          />
          <p className="titlcomponents" style={{ margin: "0 auto 64px" }}>
            ما را در شبکه های اجتماعی دنبال کنید:
          </p>
          <BesazComponent
            img={BesazComponentImg}
            title={"تبلیغات بدون محدودیت"}
            desc={
              "سرویس شنیده شو از طریق درج تبلیغات پویا در پادکست، نه تنها صمیمیت و کیفیت تبلیغات سنتی را حفظ می‌کند بلکه تمام محدودیت‌های آن را نیز حذف می‌کند. در شنیده شو، آمار و ارقام دقیقی از عملکرد تبلیغات خود دریافت می‌کنید. پیام تبلیغاتی شما به صورت ثابت روی یک اپیزود قرار نمی‌گیرد و می‌توانید در هر کمپین پیام متفاوتی را به گوش شنوندگان برسانید."
            }
          />
          <BesazComponent
            img={BesazComponentImg}
            title={"تبلیغات هدفمند"}
            desc={
              "شنیده شو به تبلیغ کنندگان این فرصت را می‌دهد که شنوندگان را براساس معیارهایی مثل محل زندگی، زمان و تاریخ گوش دادن و حتی نوع دستگاه و سیستم عاملی که از طریق آن پادکست گوش می‌دهند، انتخاب کنند. همچنین در این سرویس تبلیغ کنندگان می‌توانند یک یا چند دسته‌بندی موضوعی خاص را انتخاب کنند تا بتوانند تبلیغات خود را روی علایق مخاطب متمرکز کنند."
            }
          />
          <BesazComponent
            img={BesazComponentImg}
            title={"تبلیغات مقرون به صرفه "}
            desc={
              "در شنیده شو، تبلیغات در پادکست را بدون هیچ محدودیت قیمتی و با هرمیزان بودجه‌ای می‌توانید شروع کنید. شیوه محاسبه نرخ تبلیغات در شنیده شو کاملا شفاف و روشن است و هزینه ها بر اساس تعداد شنیده شدن تبلیغات شما محاسبه می‌شود."
            }
          />
          <div className="besazpanelpod">
            <p className="titlcomponents" style={{ margin: "42px auto 39px" }}>
              پنل پادکستر پادکده، یک تصویر کامل از عملکرد پادکست شما
            </p>
            <div className="besazpanelpodimg">
              <img src={MacAnalysis} alt={"MacAnalysis"} />
            </div>
            <div className="besazpanelpodcontent">
              <p className="titlebesazpanelpod">برقراری ارتباط با شنوندگان</p>
              <p className="descbesazpanelpod">
                ارتباط شما با شنوندگان‌تان از مهمترین عوامل افزایش مخاطب است. ما
                این کار را برای شما آسان کرده‌ایم. کامنت‌های اپیزودهای مختلف
                پادکست خود را به صورت کاملا دسته‌بندی شده ببینید و پاسخ دهید.
              </p>
              <SlideBar className={"sidebarbesaz"} />
            </div>
          </div>
          <div className="header-form">
            <p className="header-form-title">ارتباط با ما</p>
            <p className="header-form-desc">
              مهم نیست تازه کار هستید، یا مدت زمان زیادی است پادکست درست
              می‌کنید، ما معتقدیم، محتوای شما لایق شنیده شدن است و امکانات ضروری
              ساخت پادکست را با بهترین کیفیت برای شما فراهم می‌کنیم. می‌توانید
              از طریق فرم زیر سوالات خود را با مشاوران ما در میان بگذارید.
            </p>
          </div>
          <Form>
            <div className="filds">
              <div className="fild-input">
                <p>نام و نام خانوادگی *</p>

                <input />
              </div>
              <div className="fild-input">
                <p>آدرس ایمیل *</p>
                <input />
              </div>
            </div>
            <div className="filds">
              <div className="fild-input">
                <p>شماره تلفن همراه *</p>
                <input />
              </div>
              <div className="fild-input">
                <p>نام پادکست</p>
                <input />
              </div>
            </div>
            <div className="filds">
              <div className="fild-input  text-send ">
                <p>توضیحات</p>
                <input />
              </div>
            </div>
            <div className="filds-footer">
              <button>ثبت درخواست</button>
            </div>
          </Form>
          <MapBesaz>
            <img src={mapbesaz} alt={"mapbesaaz"} />
            <p className="title-map">مهاجرت به پادکده</p>
            <p className="title-map-desc">پادکست دارید؟</p>
            <p className="desc-map">
              با چند کلیک به پادکده مهاجرت کنید و از خدمات نامحدود و رایگان ما
              استفاده کنید. فرآیند تغییر بسیار ساده و آسان است. داده‌های شما به
              هیچ وجه از دست نمی‌روند و تغییری نخواهند کرد. پادکست شما به محض
              انتقال در دسترس شنوندگان خواهد بود.
            </p>
            <button> مهاجرت به پادکده</button>
          </MapBesaz>
        </Style>
      </MainLayout>
    );
  }
}
