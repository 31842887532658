import { useEffect } from 'react';
import { useLocation } from 'react-router'
import { lang } from '../../App';

function PageTitles() {
    const history = useLocation();
    useEffect(() => {
        let data;
        if(lang === "en"){
            data = titleDataEn[history.pathname]
        } else {
            data = titleData[history.pathname]
        }
        if(data) {
            document.title = data.title;
            let meta = document.querySelector('meta[name=description]');
            if(meta){
                meta.remove();
            }
            let desc = document.createElement("meta");
            desc.name = 'description';
            desc.content = data.desc;
            document.getElementsByTagName('head')[0].appendChild(desc);
        } else {
            if(lang === "en"){
                document.title = 'Podkadeh';
            } else {
                document.title = 'پادکده';
            }
        }
        return () => {
        }
    }, [history]);

    return (null);
}

export default PageTitles;



const titleData = {
    '/': {
        title: 'پادکده؛ بساز',
        desc: 'پادکده؛ بشنو بهترین اپلیکیشن پخش پادکست که با هدف خلق تجربه بهتر برای شنوندگان پادکست فارسی طراحی شده است. اپلیکیشن بشنو را از اینجا دانلود کنید.'
    },
    '/besaz-landing': {
        title: 'پادکده کجاست؟',
        desc: 'معرفی پادکده و سرویس‌های آن را از زبان بنیان‌گذاران و کارمندان آن بشنوید. ما در تمام ساعت‌های کاری خود تلاش می‌کنیم تا تجربه‌ای لذت‌بخش برای سازندگان و شنوندگان پادکست فارسی خلق کنیم.'
    },
    '/termsofservice': {
        title: 'پادکده؛ شرایط و قوانین',
        desc: '',
    },
    '/aboutus': {
        title: 'پادکده؛ درباره ما',
        desc: '',
    },
    '/contactus': {
        title: 'پادکده؛ تماس با ما',
        desc: '',
    },
}

const titleDataEn = {
    '/': {
        title: 'Podkadeh - Besaz',
        desc: 'Podkadeh - Besaz'
    },
    '/dynamic-ads': {
        title: 'Podkadeh - Dynamic Ad Solution',
        desc: 'Podkadeh - Dynamic Ad Solution'
    },
    '/besaz-landing': {
        title: 'Where is Podkadeh?',
        desc: 'Podkadeh - Besaz'
    },
    '/termsofservice': {
        title: 'Podkadeh - Terms and conditions',
        desc: 'Podkadeh - Terms and conditions'
    },
    '/aboutus': {
        title: 'Podkadeh - About us',
        desc: 'Podkadeh - About us'
    },
    '/contactus': {
        title: 'Podkadeh - Contact us',
        desc: 'Podkadeh - Contact us'
    },
}