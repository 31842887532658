import React, { useRef } from "react";
import styled from "styled-components";
import { LinesOne } from "../Lines/LinesOne";
import { NavPodkadehSocial2 } from "../sochial/NavPodkadehSocial2";
import { Language } from "../../Language/Language";
import { lang } from "../../App";
const Style = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
    grid-column: 2/14;
    position: relative;
    .content {
        margin: 0 17%;
        padding: 2rem 2rem 4rem;
        border: 3px solid var(--n-text-6);
        box-shadow: var(--n-shadow-1);
        background: var(--n-grey-05);
    }
    .lines {
        position: absolute;
        width: 150px;
    }
    .top {
        top: -2rem;
        flex-direction: column-reverse;
    }
    .bottom {
        bottom: -2.5rem;
        flex-direction: column;
    }
    .left {
        left: 17%;
        align-items: flex-start;
        .line {
          transform-origin: right;
        }
    }
    .right {
        right: 17%;
        align-items: flex-end;
        .line {
          transform-origin: left;
        }
    }
    .socials {
        position: absolute;
        top: calc(100% - 3rem);
        left: 0;
        right: 0;
    }
    @media screen and (max-width: 1000px) {
        .content {
            margin: 0 0;
            padding: 2rem 2rem 3rem;
        }
        .lines {
            position: absolute;
            width: 70px;
        }
        .left {
            left: 0;
        }
        .right {
            right: 0;
        }
        .bottom {
            bottom: -3rem;
        }
        .socials {
            position: absolute;
            top: calc(100% - 2.8rem);
            left: 0;
            right: 0;
        }
    }

`
export default function SmallContact(props) {
    
    return (
        <Style>
            <div className="content">
            {Language[lang].a4Desc}
            </div>
            <LinesOne className="lines top left"/>
            <LinesOne className="lines top right"/>
            <LinesOne className="lines bottom left"/>
            <LinesOne className="lines bottom right"/>
            <div className="socials">
                <NavPodkadehSocial2 />
            </div>
        </Style>
    )
}