import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IconInstagram } from "../../Assets/Icons/IconInstagram";
import { IconLinkdin } from "../../Assets/Icons/IconLinkdin";
import { IconTwitter } from "../../Assets/Icons/IconTwitter";
import { IconClose } from "../../Assets/Icons/IconClose";
import { IconBesaz } from "../../Assets/Icons/IconBesaz";
import { Language } from "../../Language/Language";
import { lang } from "../../App";

const DivAside = styled.div`
  @media screen and (min-width: 1000px) {
    display: none !important ;
  }
  background: #323130;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  &.notActive-rtl {
    right: -1000px;
    top: 0;
  }
  &.notActive-ltr {
    left: -1000px;
    top: 0;
  }
  //erfan edit
  &.active-rtl {
    right: 0px;
    top: 0;
    z-index: 1000;
  }
  &.active-ltr {
    left: 0px;
    top: 0;
    z-index: 1000;
  }
  .sidebarmenu {
    margin: 1rem 0;
    height: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    //margin dar design nabod v barayy dide shodan estefade shode
    ul {
      margin:0 24px;
      list-style: none;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      li {
        margin-bottom: 9px;
        a {
          text-decoration: none;
          transition: all 0.15s ease-in-out;
          
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 27px;
          display: flex;
          color: #e5e5e5;
          align-items: center;
        }
      }
    }
  }
  .spreader {
    flex-grow: 1;
  }
  .btns {
    margin: 12px 24px;
  }
  .nav-cta {
    width: calc(100% - 51px);
    display: flex;
    border: 3px solid var(--text-42);
    background: transparent;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    padding: 12px 24px;
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
    color: var(--text-42);
    text-decoration: none;
    :hover {
      color: #ffffff;
      svg {
        fill: #ffffff;
      }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 32px;
      height: 32px;
      fill: var(--text-42);
      margin-left: 8px;
    }
  }
  .iconsochialmedia {
    align-items: baseline;
    display: flex;
    justify-content: center;
    /* 
    age khastim icon ha vasat chin bashn
    justify-content: flex-start;
    margin-right: 24px; 
    */
    margin-top: 24px;
    margin-bottom: 24px;
    a {
      margin-left: 12px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
export default function BergerMenu(props) {
  //erfan edit

  function readState() {
    let state = props.sideState;
    if (window.innerWidth < 1000) {
      return state;
    }
    return true;
  }

  return (
    <DivAside className={!readState() ? "notActive-"+Language[lang].dir : "active-"+Language[lang].dir}>
      <div className="sidebarmenu">
        <ul>
          <li
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "4px",
            }}
            onClick={() =>
              props.sideState ? props.funcSide(false) : props.funcSide(true)
            }
          >
            <IconClose />
          </li>
          <li>
            <Link to="/">{Language[lang].besaz}</Link>
          </li>
          {/* <li>
            <Link to="/besaz-landing">پادکده کجاست</Link>
          </li> */}
          {
            (lang === "fa") ? (
              <li>
                <a href="https://shenidesho.com">{Language[lang].forbus}</a>
              </li>
            ): ""
          }
          {
            (lang === "en") ? (
              <li>
                <Link to="/dynamic-ads">{Language[lang].forads}</Link>
              </li>
            ) : ""
          }
          <li>
            <Link to="/aboutus">{Language[lang].aboutus}</Link>
          </li>
          <li>
            <Link to="/contactus">{Language[lang].contactus}</Link>
          </li>
          <div style={{flexGrow: 1}}></div>
          {
            (lang === "en") ? (
              <li>
                <a href="https://podkadeh.com">Persian</a>
              </li>
            ): ""
          }
          {/* <li>
            <Link to="/">پادکده را از کجا دانلود کنیم؟</Link>
          </li> */}
        </ul>
        {/* <div className="spreader"></div> */}
        {/* <div className="btns">
          <a href="https://podkadeh.com/podcaster" className="nav-cta">
            <IconBesaz/>
            {Language[lang].loginpod}
          </a>
        </div> */}
        <div className="iconsochialmedia">
          <a href="https://twitter.com/podkadeh">
            <IconTwitter className={"icon-sochial-footer"} />
          </a>
          <a href="https://www.instagram.com/podkadeh">
            <IconInstagram className={"icon-sochial-footer"} />
          </a>
          <a href="https://www.linkedin.com/company/podkadeh">
            <IconLinkdin className={"icon-sochial-footer"} />
          </a>
        </div>
      </div>
    </DivAside>
  );
}
