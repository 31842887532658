import React from "react";
import styled from "styled-components";
import MainLayout from "./Layout/MainLayout";
import ImgHeader from "../Assets/Images/Terms/terms.jpg";
import TopImage from "../Components/Helper/TopImage";

const Style = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  margin-top: 60px;
  margin-bottom: 70px;
  align-items: center;
  justify-content: center;
  position: relative;
  --fs-about-us-title: 32px;
  --fs-about-us-title-2: 24px;
  --fs-about-us-desc: 20px;
  @media screen and (max-width: 1750px) {
    --fs-about-us-title: 28px;
    --fs-about-us-title-2: 22px;
    --fs-about-us-desc: 17px;
  }
  @media screen and (max-width: 1500px) {
    --fs-about-us-title: 24px;
    --fs-about-us-title-2: 20px;
    --fs-about-us-desc: 15px;
  }
  @media screen and (max-width: 1250px) {
    --fs-about-us-title: 20px;
    --fs-about-us-title-2: 18px;
    --fs-about-us-desc: 14px;
  }
  @media screen and (max-width: 1000px) {
    --fs-about-us-title: 32px;
    --fs-about-us-title-2: 24px;
    --fs-about-us-desc: 20px;
  }
  @media screen and (max-width: 600px) {
    --fs-about-us-title: 20px;
    --fs-about-us-title-2: 18px;
    --fs-about-us-desc: 16px;
  }
  .container-terms {
    width: 100%;
    grid-column: 2/14;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 0.78vw));
    /* flex-direction: column; */
    grid-gap: 1.56vw;
    padding: 2rem 2.5rem;
    background: var(--primary-1);
    box-shadow: var(--shadow-2);
    border-radius: 16px;
    margin: 0.5rem 0;
    box-sizing: border-box;
    .image {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-size: var(--fs-about-us-title);
        font-weight: 500;
        margin-bottom: calc( var(--fs-about-us-title) * 0.5);
      }
      h4 {
        font-size: var(--fs-about-us-title-2);
        font-weight: 500;
        margin-bottom: calc( var(--fs-about-us-title-2) * 0.5);
      }
      .desc {
        font-size: var(--fs-about-us-desc);
        margin-top: calc( var(--fs-about-us-desc) * 0.5);
        text-align: justify;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    margin-top: 55px;
    grid-template-columns:
      14px repeat(6, calc(calc(calc(100% - 60px) / 6) - calc(80px / 6)))
      14px;
    grid-column-gap: 16px;
    .container-terms {
      grid-column: 1/9;
      border-radius: 0;
      padding: 2rem 1.5rem;
      .image {
        grid-column: span 2;
      }
      .content {
        grid-column: span 2;
      };
    }
   
  }
  @media screen and (max-width: 600px) {
    margin-top: 50px;
    .container-terms {
      padding: 2rem 1rem;
    }
  }
`;

export default function TermsOfService(props) {
  return (
    <MainLayout {...props}>
      <Style>
        <TopImage image={ImgHeader}/>
        {/* <div className="header-TermsOfService">قوانین و شرایط همکاری</div> */}
        <div className="container-terms">
          <div className="content" style={{gridColumn: 'span 2'}}>
            <h3> شرایط و قوانین </h3>
            <div className="desc">
              با استناد به ماده 74 قانون تجارت الکترونیک مصوب 1382/10/17 مجلس
              شورای اسلامی و با عنایت به اینکه پلتفرم پادکده مصداق بستر مبادلات
              الکترونیکی صوتی و تصویر است، مسئولیت نقض حقوق تصریح شده مؤلفان در
              قانون فوق از قبیل تکثیر، اجرا، توزیع و یا هرگونه محتوای خلاف
              قوانین کشور ایران بر عهده کاربران است و در صورتی که مشخص گردد، این
              قوانین توسط کاربر نقض شده است، پادکده موظف است تا آن فایل را حذف
              نماید.
              <br />
              <br />
              <br />
              <li>
                پلتفرم پادکده تابع کلیه قوانین موضوعه کشور به‌خصوص قانون تجارت
                الکترونیک است لذا نقض هر رفتاری که متضمن نقض هر یک از قوانین کشور
                باشد مجرمانه تلقی می‌گردد و قابل پیگیری است.
              </li>
              <br />
              <li>
                گزارش رعایت نکردن حق نشر را باید از طریق ایمیل برای ما ارسال
                نمایید تا ما بتوانیم به درخواست شما را رسیدگی کنیم. ارسال مدارک
                برای اثبات حق مالکیت محتوا لازم است و مدارک شما نزد پادکده محفوظ
                خواهد ماند.
              </li>
              <br />
              <br />
              <h4>قوانین اضافه کردن پادکست در پادکده:</h4>
              <br />
              <li>
                توجه داشته باشید باید حق نشر محتوای بارگذاری شده را رعایت کنید.
              </li>
              <li>
                محتوای صوتی، توضیحات و کاور پادکست نباید با شئونات اخلاقی و عرف
                جامعه اسلامی مغایرت داشته باشد.
              </li>
              <li>محتوا نباید منجر به ایجاد تنش و التهاب سیاسی شود.</li>
              <li>
                محتوا نباید نسبت به مقامات سیاسی و اقلیت‌های قومی و مذهبی
                اهانت‌آمیز باشد.
              </li>
              <li>
                تمسخر شهروندان در محتوا ممنوع است و در صورت گزارش، اپیزود و یا
                پادکست با این مفهوم از بستر پادکده حذف می‌شود.
              </li>
              <br />
              <p>
                * در صورت عدم رعایت قوانین فوق، پادکده مي‌تواند ایپزود و یا پادکست
                مربوطه را حذف نماید
              </p>
              <br />
              <br />
              <h4>قوانین ثبت نظرات:</h4>
              <br />
              <p>نظراتی که شامل موارد زیر باشند، حذف خواهند شد:</p>
              <br />
              <li>نقض قوانین جمهوری اسلامی ایران</li>
              <li>
                تبلیغ به نفع یا علیه پادکست‌ها یا سایر تولید کنندگان محتوای صوتی
              </li>
              <li>هر نوع محتوای متناقض عرف جامعه</li>
              <li>توهین به مقدسات، اقوام یا اشخاص استفاده از الفاظ رکیک</li>
              <li>هرگونه لینک به سایت‌های دیگر</li>
              <li>شماره تلفن، ایمیل و اطلاعات تماس</li>
              <li>نام کاربری افراد در سایت‌های مختلف مانند Yahoo ID و ...</li>
              <li>درخواست و دعوت به چت</li>
              <li>درخواست نرم‌افزارهای عبور از فیلترینگ</li>
              <li>نظراتی غیر‌مرتبط با محتوای در حال پخش</li>
              <br />
              <br />
              <br />
              <h3>حریم حصوصی کابر</h3>
              <br />
              ما در پادکده به حریم خصوصی شما کاربران گرامی احترام می‌گذاریم و خود را موظف به حفاظت از اطلاعاتی می‌دانیم که شما در اختیار ما می‌گذارید. جمع‌آوری و پردازش برخی از اطلاعات کاربران برای ما امری ضروری و اجتناب‌ناپذیر است. به منظور اطمینان خاطر شما از بابت اطلاعات جمع‌آوری شده این سند تحت عنوان "سیاست‌نامه حریم خصوصی" طراحی شده است. 
              <br />
              <br />
              *این سند ممکن است دستخوش تغییر و تجدیدنظر شود. به موجب الزامات قانونی هرگونه تغییر در آن، از طریق حساب کاربری به اطلاع شما کاربر گرامی خواهد رسید. 
              <br />
              <br />
              <br />
              <h4>اطلاعاتی که توسط پادکده از کاربران جمع‌آوری می‌شود:</h4>
              این اطلاعات ممکن است هنگام نصب اپلیکیشن و یا حین استفاده از آن {'(اطلاعات فنی و گزارش‌های سرور که در خود سرور نگهداری می‌شوند)'} جمع‌آوری شود. برخی اطلاعات نیز ممکن است به‌طور مستقیم از کاربر گرفته شود (هنگام ایجاد حساب کاربری، ثبت نظر، تماس و مکاتبه با پشتیبانی و یا تکمیل هر فرم یا هرگونه ارائه اختیاری اطلاعات توسط کاربر). این اطلاعات شامل موارد زیر است:
              <br />
              <br />

              <li>نوع دستگاه، زبان و نوع سیستم عامل دستگاه شما</li>
              <li>جست‌وجوهای انجام شده و نتایج انتخاب‌شده</li>
              <li>پادکست‌های دنبال شده و شنیده شده</li>
              <li>ما ممکن است از کوکی‌ها {'(cookies)'} در جمع‌آوری اطلاعات استفاده کنیم. کوکی فایلی است که به درخواست یک سایت، توسط مرورگر ایجاد می‌شود و به سایت امکان ذخیره بازدید‌های شما و مناسب‌سازی آن‌ها را فراهم می‌نماید. به عنوان مثال ما با استفاده از کوکی می‌توانیم محتوایی را به شما پیشنهاد بدهیم که متناسب با علاقه‌ها و ترجیحات شما باشد. بسیاری از مرورگرها امکان غیرفعال کردن کوکی را فراهم کرده‌اند، به این منظور می‌توانید راهنمای مرورگرها را مطالعه کنید. </li>
              <li>اطلاعاتی که بعد از ایجاد حساب کاربری در اختیار پادکده قرار می‌دهید.</li>
              <li>اطلاعات فنی و گزارش‌های سرور شامل آدرس پروتکل اینترنت {'(IP)'}، نوع شبکه و نوع و نسخه مرورگر، اطلاعات مهم دستگاه هنگام وقفه‌ها {"(Crashes)"} شامل وضعیت روت بودن یا نبودن دستگاه، میزان کل رم و میزان فضای پر آن هنگام وقفه، روز و ساعت  رخدادها، خاموش یا روشن بودن شبکه بی‌سیم، خاموش یا روشن بودن شبکه دیتا.</li>
              <br />
              <br />
              <br />
              <h4>موارد استفاده پادکده از اطلاعات کابران:</h4>
چه از نظر قانونی و چه از نظر اخلاقی ما موظف به حفظ امنیت اطلاعات و پرونده‌های شما هستیم. اطلاعات شخصی شما نیز صرفاً جهت تعاملات بعدی به صورت کدگذاری‌ شده نگهداری می‌شوند و در جهت ارائه تجربه کاربری بهتر از آن‌ها استفاده می‌شود. ما به هیچ وجه از اطلاعات تماس شما جهت ارسال پیام‌های تبلیغاتی استفاده نمی‌کنیم.
<br />
<br />
همکاران بخش پشتیبانی برای ارائه پشتیبانی بهتر و خدمات با کیفیت‌تر به برخی از اطلاعات شما دسترسی دارند. تمامی اطلاعات شخصی شما نزد ما محفوظ خواهد ماند و در اختیار هیچ فرد یا سازمانی قرار نخواهد گرفت. بدیهی است که مراجع قانونی با ارائه حکم دادگاه قادرند به این اطلاعات دسترسی داشته باشند. به طور کلی جز در مواردی که قانون حکم می‌کند ما موظفیم از اطلاعات شما حفاظت و حراست نماییم.
<br />
<br />
              <br />
<h4>امنیت اطلاعات شخصی کاربران:</h4>
پاکده با بالاترین سطح امنیت نرم‌افزاری و سخت‌افزاری طراحی شده است. اطلاعات شما کاربر گرامی را به صورت رمزنگاری شده در سرور‌های خود ثبت و نگهداری می‌کنیم و تنها در جهت خلق تجربه بهتر برای شما از آن استفاده خواهد شد.

            </div>
          </div>
        </div>
      </Style>
    </MainLayout>
  );
}
