import React, { useRef } from "react";
import styled from "styled-components";
import arrow from "../../Assets/new1/arrow-right.svg"
import TopaboutusImg from "../../Assets/new1/TopaboutusImg";
import { Language } from "../../Language/Language";
import { lang } from "../../App";
const Style = styled.div`
    margin-top: 7rem;
    margin-bottom: 7rem;
    grid-column: 2/14;
    /* min-height: 100vh; */
    display: flex;
    position: relative;
    .image {
        max-width: 750px;
        position: absolute;
        width: 55%;
        top: -30%;
        right: 0;
    }
    .contents {
        margin-right: 15%;
        border: 3px solid var(--n-text-6);
        border-radius: 4px;
        box-shadow: var(--n-shadow-1);
        background: var(--n-grey-05);
        display: flex;
        align-items: right;
        flex-direction: column;
        padding: 3rem 0.5rem 3rem;
        .title {
            font-size: 56px;
            font-weight: 900;
            margin-bottom: calc( var(--fs-about-us-title) * 0.5);
            margin-right: 55%;
            margin-left: 2rem;
        }
        .desc {
            margin-right: 55%;
            font-size: var(--fs-about-us-desc);
            margin-top: calc( var(--fs-about-us-desc) * 0.5);
            text-align: justify;
            margin-left: 2rem;
            color: var(--n-grey-7);
        }
    }
    .more-btn {
        position: absolute;
        left: 0;
        bottom: -7rem;
        width: calc(45% - 1rem);
        display: flex;
        .line {
            z-index: 0;
            position: absolute;
            top: 1.25rem;
            left: 0;
            height: 3px;
            width: calc(100% - 1rem);
            background-color: var(--n-text-6);
        }
        .btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            :hover {
                .icon {
                    box-shadow: var(--n-shadow-1-hover);
                    top: 2.5px;
                    left: 2.5px;
                }
            }
        }
        .icon {
            position: relative;
            top: 0;
            left: 0;
            z-index: 2;
            width: 2rem;
            height: 2rem;
            border-radius: 100rem;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: var(--n-shadow-1);
            background: var(--n-grey-05);
            border: 3px solid var(--n-text-6);
            transition: var(--trans-1);
            img {
                width: 24px;
                height: 24px;
                display: block;
            }
        }
        .text {
            font-size: 14px;
            font-weight: 700;
        }
    }
    @media screen and (max-width: 1000px) {
        margin-top: 35vw;
        margin-bottom: 2rem;
        .contents {
            margin-right: 0%;
            padding: 45vw 0.5rem 2rem;
            .title {
                font-size: 32px;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
                text-align: center;
            }
            .desc {
                text-align: center;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
            }
        }
        .image {
            width: 100%;
            top: -38vw;
            right: 0;
        }
        .more-btn {
            display: none;
        }
    }
`
export default function AboutUsTopPage(props) {
    const ref1 = useRef();
    const moveDown = (e) => {
        window.scrollTo({
          top: ref1.current.offsetHeight + 250,
          behavior: "smooth",
        })
    }
    return (
        <Style ref={ref1}>
            <div className="image">
                {/* <img src={image1} alt="podkadeh"/> */}
                <TopaboutusImg/>
            </div>
            <div className="contents">
                <h3 className="title"> {Language[lang].a1Title} </h3>
                <div className="desc">
                {Language[lang].a1Desc}
                </div>
            </div>
            <div className="more-btn">
                <div className="line"></div>
                <div className="btn">
                    <div className="icon" onClick={moveDown}>
                        <img src={arrow} alt={"arrow"}/>
                    </div>
                    <div className="text">{Language[lang].showmore}</div>
                </div>
            </div>
        </Style>
    )
}