import React from "react";
import styled from "styled-components";
import MainLayout from "./Layout/MainLayout";
import { Link } from "react-router-dom";
import ImgHeadreAboutUs from "../Assets/Images/ImgHeadreAboutUs.jpg";
import TopImage from "../Components/Helper/TopImage";

const Style = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  margin-top: 60px;
  margin-bottom: 20vh;
  align-items: center;
  justify-content: center;
  position: relative;
  --fs-about-us-title: 32px;
  --fs-about-us-title-2: 24px;
  --fs-about-us-desc: 20px;
  @media screen and (max-width: 1750px) {
    --fs-about-us-title: 28px;
    --fs-about-us-title-2: 22px;
    --fs-about-us-desc: 17px;
  }
  @media screen and (max-width: 1500px) {
    --fs-about-us-title: 24px;
    --fs-about-us-title-2: 20px;
    --fs-about-us-desc: 15px;
  }
  @media screen and (max-width: 1250px) {
    --fs-about-us-title: 20px;
    --fs-about-us-title-2: 18px;
    --fs-about-us-desc: 14px;
  }
  @media screen and (max-width: 1000px) {
    --fs-about-us-title: 32px;
    --fs-about-us-title-2: 24px;
    --fs-about-us-desc: 20px;
  }
  @media screen and (max-width: 600px) {
    --fs-about-us-title: 20px;
    --fs-about-us-title-2: 18px;
    --fs-about-us-desc: 16px;
  }
  .container-terms {
    width: 100%;
    grid-column: 2/14;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 0.78vw));
    /* flex-direction: column; */
    grid-gap: 1.56vw;
    padding: 2rem 2.5rem;
    background: var(--primary-1);
    box-shadow: var(--shadow-2);
    border-radius: 16px;
    margin: 0.5rem 0;
    box-sizing: border-box;
    .image {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-size: var(--fs-about-us-title);
        font-weight: 500;
        margin-bottom: calc( var(--fs-about-us-title) * 0.5);
      }
      a {
        color: #fff;
        text-decoration: none;
        :hover {
          color: #aaa;
        }
      }
      h4 {
        font-size: var(--fs-about-us-title-2);
        font-weight: 500;
        margin-bottom: calc( var(--fs-about-us-title-2) * 0.5);
      }
      .desc {
        font-size: var(--fs-about-us-desc);
        margin-top: calc( var(--fs-about-us-desc) * 0.5);
        text-align: justify;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    margin-top: 55px;
    grid-template-columns:
      14px repeat(6, calc(calc(calc(100% - 60px) / 6) - calc(80px / 6)))
      14px;
    grid-column-gap: 16px;
    .container-terms {
      grid-column: 1/9;
      border-radius: 0;
      padding: 2rem 1.5rem;
      .image {
        grid-column: span 2;
      }
      .content {
        grid-column: span 2;
      };
    }
   
  }
  @media screen and (max-width: 600px) {
    margin-top: 50px;
    .container-terms {
      padding: 2rem 1rem;
    }
  }
`;

export default function NotMatch(props) {
  return (
    <MainLayout {...props}>
      <Style>
        <TopImage image={ImgHeadreAboutUs}/>
        {/* <div className="header-TermsOfService">قوانین و شرایط همکاری</div> */}
        <div className="container-terms">
          <div className="content" style={{gridColumn: 'span 2'}}>
            <h3> صفحه مورد نظر پیدا نشد </h3>
            <Link to={'/'}> صفحه اصلی </Link>
          </div>
        </div>
      </Style>
    </MainLayout>
  );
}
