import React from "react";
export const IconReplay = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4974 7.46981H11.8307C8.15073 7.46981 5.16406 10.4104 5.16406 14.0337C5.16406 17.657 8.15073 20.5977 11.8307 20.5977H26.4974"
        stroke="#E5E7EB"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.4297 17.3157L26.843 20.6764L23.4297 24.0371"
        stroke="#E5E7EB"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
