import React from "react";
import styled from "styled-components";
import { IconUserCommentBlog } from "../../Assets/Icons/IconUserCommentBlog";
import { Link } from "react-router-dom";
import { IconSendCm } from "../../Assets/Icons/IconSendCm";
const Style = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 125px;
  .input {
    height: 112px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #9ba4ab;
    border-radius: 10px;
  }
  .send-svg {
    margin-left: 76px;
    @media screen and (max-width: 1500px) {
      margin-left: 50px;
    }
  }
  .prof-svg {
    margin-right: 30px;
  }
  input {
    outline: none;
    background: none;
    border: none;
    
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #b3b3b3;
    width: 80%;
    height: 80%;
  }
  p {
    
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #f2f2f2;
    margin-bottom: 8px !important;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 39px;
    a {
      svg {
        width: 24px;
        margin-left: 29px;
      }
    }
    p {
      
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #f2f2f2;
      margin: 30px 0 8px;
    }
    .input {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #9ba4ab;
      border-radius: 10px;
    }
    .send-svg {
      margin-left: 25px;
      width: 24px !important;
    }
    .prof-svg {
      margin-right: 12px;
      width: 28px !important;
      height: 28px !important;
    }
    svg {
      margin-right: 12px;
      margin-left: 4px;
      width: 28px;
      height: 28px;
    }
    input {
      outline: none;
      background: none;
      width: 80%;
      height: 80%;
      border: none;
      font-size: 16px;
      color: #c4c4c4;
    }
  }
`;
export default function Blog_Input_Comment({ className, style }) {
  return (
    <Style style={style} className={className}>
      <p>دیدگاه خود را بنویسید</p>
      <div className="input">
        <IconUserCommentBlog className="prof-svg" />
        <input type={"text"} />
        <Link to="/">
          <IconSendCm className="send-svg" />
        </Link>
      </div>
    </Style>
  );
}
