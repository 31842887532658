import React from "react";
import styled from "styled-components";
import Blogs from "../../Assets/Images/blog.png";
import { Link } from "react-router-dom";

const Style = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-2);
  a {
    color: var(--text-2);
    text-decoration: none;
  }
  .img {
    display: flex;
    justify-content: center;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 24px;
  }
  .title-content {
    
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
  }
  .desc {
    
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    width: 95% !important;
  }
  .blog {
    color: #ffffff !important ;
  }

  @media screen and (max-width: 1250px) {
    margin: 0 1rem;
    a {
      text-decoration: none;
      color: #ffffff;
    }
    .img {
      display: flex;
      justify-content: center;
      img {
      }
    }
    .desc {
      width: 90% !important;
    }
    .content {
      width: auto !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media screen and (max-width: 600px) {
    color: var(--text-2);
    margin: 0 !important;
    margin-bottom: 40px !important;
    a {
      color: var(--text-2);
      text-decoration: none;
    }
    .img {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        border-radius: 10px !important;
      }
    }
    .content {
      width: auto !important;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title-content {
        
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        margin-top: 8px !important;
      }
      .desc {
        
        font-style: normal;
        font-weight: 400;
        width: 90% !important;
        font-size: 8px;
        line-height: 14px;
      }
    }
  }
`;
export default function BlogComponents({ className, style, title, text }) {
  return (
    <Style style={style} className={className}>
      <Link to="/BlogDetails">
        <div className="img">
          <img src={Blogs} alt="Blogs" />
        </div>
        <div className="content blog">
          <p className="title-content"> {title} </p>
          <p className="desc"> {text} </p>
        </div>
      </Link>
    </Style>
  );
}
