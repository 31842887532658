import React from "react";
import styled from "styled-components";
import { IconInstagram } from "../../Assets/Icons/IconInstagram";
import { IconLinkdin } from "../../Assets/Icons/IconLinkdin";
import { IconTwitter } from "../../Assets/Icons/IconTwitter";
import { LogoPodkadeh } from "../../Assets/LogoPodkadeh";

const Styles = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  padding: 3rem 0 0;
  --fs-title: 34px;
  --fs-svg: 56px;
  @media screen and (max-width: 1750px) {
    --fs-title: 29px;
    --fs-svg: 48px;
  }
  @media screen and (max-width: 1500px) {
    --fs-title: 24px;
    --fs-svg: 40px;
  }
  @media screen and (max-width: 1250px) {
    --fs-title: 20px;
    --fs-svg: 32px;
  }



  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-left: 40px;
      width: var(--fs-svg);
      height: var(--fs-svg);
    }
  }
  .right {
    margin-left: 80px;
    svg {
      margin-left: 16px;
      width: var(--fs-svg);
      height: var(--fs-svg);
    }
    font-weight: 500;
    font-size: var(--fs-title);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 32px !important;
        width: 28px !important;
        height: 28px !important;
      }
    }
    .right {
      svg {
        margin-left: 16px;
        width: 32px !important;
        height: 32px !important;
      }
      font-weight: 500;
      font-size: 18px !important;
      line-height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 800px) {
    .right {
      margin-left: 50px !important;
    }
  }
  @media screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 !important;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 16px !important;
        margin-left: 0 !important;
        width: 24px !important;
        height: 24px !important;
      }
    }
    .right {
      margin-left: 0 !important;
      margin: 0 0 1rem 0;
      svg {
        margin-left: 4px !important;
        width: 24px !important;
        height: 24px !important;
      }
      
      font-style: normal;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const NavPodkadehSochial = ({ style, className }) => {
  return (
    <Styles style={style} className={className}>
      <div className="right">
        <LogoPodkadeh />
        پادکده در شبکه های اجتماعی
      </div>
      <div className="left">
        <a href="https://twitter.com/podkadeh">
          <IconTwitter className={"icon-sochial-footer"} />
        </a>
        <a href="https://www.instagram.com/podkadeh">
          <IconInstagram className={"icon-sochial-footer"} />
        </a>
        <a href="https://www.linkedin.com/company/podkadeh">
          <IconLinkdin className={"icon-sochial-footer"} />
        </a>
      </div>
    </Styles>
  );
};
