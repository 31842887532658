import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const Style = styled.div`
  width: 100%;
  display: flex;
  margin: 40px 0;
  align-items: center;
  justify-content: flex-start;
  p {
    
    font-style: normal;
    font-weight: 500;
    font-size: var(--fs--20-14-14);
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #e5e7eb;
    margin-left: 41px;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a19f9d;
    background: #f2f2f2;
    border-radius: 17px;
    text-decoration: none;
    margin-left: 30px;
    width: 108px;
    height: 34px;
  }
  @media screen and (max-width: 1000px) {
    a {
      
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
      line-height: 28px;
      display: flex;
      align-items: center;
      width: 70px;
      height: 20px;
    }
  }
`;
export default function BlogTags({ className, style, text, tag }) {
  return (
    <Style style={style} className={className}>
      <p>{text}</p>
      <Link to="/"> {tag} </Link>
    </Style>
  );
}
