import React from "react";
export const IconArrowLeft = ({ stroke, style, className }) => {
  return (
    /////////stroke by props
    <svg
      style={style}
      className={className}
      width="12"
      height="24"
      viewBox="0 0 12 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0004 22.5599L2.30711 13.8666C1.28044 12.8399 1.28044 11.1599 2.30711 10.1333L11.0004 1.43994"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
