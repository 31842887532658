import React, { useRef } from "react";
import styled from "styled-components";
import arrow from "../../Assets/new1/arrow-right.svg"
import tophome from "../../Assets/new1/hometop.png";
import tophomem from "../../Assets/new1/hometop-m.png";
import { TextBtn } from "../Inputs/TextBtn";
import { LinesOne } from "../Lines/LinesOne";
import { Language } from "../../Language/Language";
import { lang } from "../../App";
const Style = styled.div`
    margin-top: 100px;
    margin-bottom: 7rem;
    grid-column: 2/14;
    /* min-height: 100vh; */
    display: flex;
    position: relative;
    gap: 2rem;
    &.home1-ltr {
        .image {
            margin-left: 7.65%;
            margin-right: auto;
            .left {
                left: 39%;
                .line {
                    transform-origin: left;
                }
            }
            .right {
                left: 8.5%;
                .line {
                    transform-origin: right;
                }
            }
        }
        .content {
            margin-left: 1rem;
            margin-right: 7.65%;
        }
    }
    .image {
        width: calc(55% - 1rem - 7.65%);
        margin-right: 7.65%;
        display: flex;
        position: relative;
        img {
            margin-top: 7%;
            width: 80%;
            height: auto;
        }
        .lines {
            position: absolute;
            width: 30.5%;
        }
        .bottom {
            bottom: -2.5rem;
            flex-direction: column;
        }
        .left {
            right: 39%;
            align-items: flex-start;
            .line {
                transform-origin: right;
            }
        }
        .right {
            right: 8.5%;
            align-items: flex-end;
            .line {
                transform-origin: left;
            }
        }
    }
    .content {
        width: calc(45% - 2rem - 7.65%);
        margin-right: 1rem;
        margin-left: 7.65%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
        .base-title {
            font-size: 40px;
            font-weight: 900;
            color: var(--text-42);
        }
        .main-title {
            font-size: 56px;
            font-weight: 900;
        }
        .sub-title {
            color: var(--n-grey-7);
            font-size: 16px;
            font-weight: 500;
            text-align: justify;
        }
        .btn-title {
            margin-top: 0.5rem;
            /* width: 50%; */
            display: flex;
            gap: 0.8rem;
        }
    }
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        margin-bottom: 1rem;
        &.home1-ltr {
            .image {
                margin-left: 0;
                margin-right: 0;
                .left {
                    left: 49%;
                }
                .right {
                    left: 13.5%;
                }
            }
            .content {
                margin-right: 0;
                margin-left: 0;
            }
        }
        .image {
            width: calc(100%);
            margin-left: 0;
            margin-right: 0;
            justify-content: center;
            img {
                margin-top: 3%;
                margin-right: 2%;
                width: 100%;
                height: auto;
            }
            .lines {
                width: 36.5%;
            }
            .bottom {
                bottom: -2.5rem;
            }
            .left {
                right: 49%;
            }
            .right {
                right: 13.5%;
            }
        }
        .content {
            margin-top: 2rem;
            width: calc(100%);
            margin-right: 0;
            margin-left: 0;
            text-align: center;
            .base-title {
                font-size: 32px;
            }
            .main-title {
                font-size: 32px;
            }
            .sub-title {
                text-align: center;
            }
            .btn-title {
                margin-top: 0.5rem;
                margin-right: auto;
                margin-left: auto;
                width: 80%;
                flex-direction: column;
                gap: 0.8rem;
            }
        }
    }
`
export default function TopHome(props) {
    const ref1 = useRef();
    const moveDown = (e) => {
        window.scrollTo({
          top: ref1.current.offsetHeight + 250,
          behavior: "smooth",
        })
    }
    return (
        <Style ref={ref1} className={"home1-"+Language[lang].dir}>
            <div className="image">
                {
                    (window.innerWidth < 1000) ? (
                        <img src={tophomem} alt={"podkadeh"}/>
                    ) : (
                        <img src={tophome} alt={"podkadeh"}/>
                    )
                }
                <LinesOne className="lines bottom left"/>
                <LinesOne className="lines bottom right"/>
            </div>
            <div className="content">
                <div className="base-title">
                {Language[lang].tophometitle}
                </div>
                <div className="main-title">
                {Language[lang].tophometitle2}
                </div>
                <div className="sub-title">
                {Language[lang].tophomedesc}
                </div>
                <div className="btn-title">
                    <TextBtn
                        type={"link"}
                        text={Language[lang].tophomecta1}
                        // onclick={sendData}
                        loading={false}
                        disabled={false}
                        color={1}
                        href={"https://podkadeh.com/podcaster"}
                    />
                    <TextBtn
                        type={"link"}
                        text={Language[lang].tophomecta2}
                        // onclick={sendData}
                        loading={false}
                        disabled={false}
                        // color={1}
                        href={"#migrate"}
                    />
                </div>
            </div>
        </Style>
    )
}