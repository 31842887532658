import React from "react";
import styled from "styled-components";
const Styles = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: row;
  margin-top: 160px;
  z-index: 999;
  position: absolute;
  span {
    cursor: pointer;
    margin: 0 6px;
    height: 6px;
    width: 50px;
    background: #c4c4c4;
    border-radius: 2px;
  }
  @media screen and (max-width: 1000px) {
    margin: 0 !important;
    margin-top: 50px !important;
    span {
      margin: 0 3px !important;
      height: 2px !important;
      width: 28px !important;
      background: #c4c4c4;
      border-radius: 2px;
    }
  }
  @media screen and (max-width: 600px) {
    margin: 0 !important;
    span {
      margin: 0 1px !important;
      height: 2px !important;
      width: 10px !important;
      background: #c4c4c4;
      border-radius: 2px;
    }
  }
`;

export const SlideBar = ({ style, className }) => {
  return (
    <Styles style={style} className={className}>
      <span></span>
      <span></span>
      <span style={{ background: "#FD502E" }}></span>
    </Styles>
  );
};
