import React from "react";
import styled from "styled-components";
const Style = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  margin: 20px 0;

  .besaz-component-container {
    grid-column: 2/14;
    background: var(--primary-1);
    box-shadow: 0px 4px 25px 3px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(
      14,
      calc(calc(84.7% / 12) - calc(17.16% / 12))
    );
    grid-column-gap: 1.56%;
  }
  .leftelement,
  .rightelement {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .rightelement {
    grid-column: 2/7;
    .deletedetimg {
      display: none;
    }
    .deletedetdetail {
      display: none;
    }
  }
  .leftelement {
    grid-column: 8/14;
    .deletedetdetail {
      display: none;
    }
    .deletedetimg {
      display: none;
    }
  }
  .titlesponsor {
    
    font-weight: 500;
    line-height: 54px;
    text-align: right;
    margin-bottom: 25px;
    font-size: var(--fs--32-24-22);
    color: var(--text-1);
  }
  img {
    width: 100%;
    height: auto;
    margin: 48px 0;
    border-radius: 8px;
  }
  desc {
    font-size: var(--fs--24-20-18);
    color: var(--text-9);
    
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    display: flex;
    align-items: center;
    text-align: right;
  }
  @media screen and (max-width: 1000px) {
    margin: 0 auto 24px !important ;
    width: 100% !important;
    background: var(--primary-1);
    display: flex;
    .besaz-component-container {
      box-shadow: none;
      border-radius: 0 !important;
      width: calc(100% - 60px);
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .topelement {
        img {
          width: 100%;
          height: auto;
          margin: 48px 0;
          border-radius: 16px;
        }
      }
      desc {
        margin-bottom: 80px !important;
      }
    }
  }
`;

export default function BesazComponent(props, className) {
  if (window.innerWidth > 1000) {
    return (
      <Style className={className}>
        <div className="besaz-component-container">
          <div className="rightelement ">
            <img
              src={props.img}
              alt={props.altsponser}
              className={props.detailright ? "deletedetimg" : ""}
            />
            <p
              className={
                props.imgright
                  ? "deletedetdetail titlesponsor "
                  : "titlesponsor"
              }
            >
              {props.title}
            </p>
            <desc className={props.imgright ? "deletedetdetail" : ""}>
              {props.desc}
            </desc>
          </div>
          <div className="leftelement ">
            <img
              src={props.img}
              alt={props.altsponser}
              className={props.detailleft ? "deletedetimg" : ""}
            />
            <p
              className={
                props.imgleft ? "deletedetdetail titlesponsor " : "titlesponsor"
              }
            >
              {props.title}
            </p>
            <desc className={props.imgleft ? "deletedetdetail " : ""}>
              {props.desc}
            </desc>
          </div>
        </div>
      </Style>
    );
  } else {
    return (
      <Style className={className}>
        <div className="besaz-component-container">
          <div className="topelement">
            <img src={props.img} alt={props.altsponser} />
          </div>
          <div className=" bottomelement ">
            <p className={" titlesponsor"}>{props.title}</p>
            <desc>{props.desc}</desc>
          </div>
        </div>
      </Style>
    );
  }
}
