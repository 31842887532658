import React from "react";
import styled from "styled-components";
import MainLayout from "./Layout/MainLayout";
import TopHome from "../Components/Home/TopHome";
import ShowMore from "../Components/Home/ShowMore";
import Section2 from "../Components/Home/Section2";
import Section3 from "../Components/Home/Section3";
import Section4 from "../Components/Home/Section4";
import Section5 from "../Components/Home/Section5";
const Style = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
`;
export default function HomeV2(props) {
  return (
    <MainLayout {...props}>
      <Style>
        <TopHome/>
        <ShowMore/>
        <Section2/>
        <Section3/>
        <Section4/>
        <Section5 {...props}/>
      </Style>
    </MainLayout>
  );
}