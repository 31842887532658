import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";
import { TextAreaWTitle } from "../Helper/TextAreaWTitle";
import { TextInputWTitle } from "../Helper/TextInputWTitle";
import { TextBtn } from "../Inputs/TextBtn";
import { Language } from "../../Language/Language";
import { lang } from "../../App";
const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  height: 100%;
  justify-content: flex-start;
  margin: 0px 0 0px;
  &.formcontact-ltr {
    direction: ltr;
  }
  .titlecontantus {
    text-align: start;
    width: 100%;
    margin-bottom: 32px;
    
    font-size: 32px;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: 0em;
  }
  .filds {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
  }
  .fild-input {
    display: flex;
    width: 48%;
    flex-direction: column;

    p {
      display: inline;
      font-size: var(--fs--24-20-18);
    }
  }
  .fildtextsend {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    .text-send {
      height: 100%;
      width: 100%;
      /* margin-bottom: 48px; */
      .fild-input {
        /* height: 240px; */
        width: 100%;
      }
      input {
        width: calc(100% - 2rem);
        height: 100%;
        padding: calc(var(--fs-textfield) * 2) calc(1rem - 1px)
          var(--fs-textfield);
        border-radius: var(--border-radius);
        border: 1px var(--border-2) solid;
        outline: none;
        transition: var(--trans-1);
        font-size: var(--fs-textfield);
        background: transparent;
        color: var(--text-2);
      }
    }
  }
  .filds-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .button {
      width: 100%;
    }
    .desc {
      width: calc(70% - 20px);
      flex-grow: 1;
      font-size: 12px;
      margin-left: 20px;
      span {
        color: #fd502e;
        font-size: 12px;
      }
    }
  }
  label {
    color: var(--text-6);
  }
  input {
    color: var(--text-1);
  }
  input:focus {
    background-color: var(--primary-1);
  }
  @media screen and (max-width: 1000px) {
    display: flex !important;
    width: calc(100% - 32px) !important;
    flex-direction: column;
    margin: 32px auto 2rem !important;
    .filds {
      display: flex;
      flex-direction: column !important;
      justify-content: space-between;
      margin: 0 !important;
    }

    .filds-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .fild-input {
      margin-bottom: 24px !important ;
      width: 100% !important;
      p {
        display: inline;
        font-size: var(--fs--24-20-18);
      }
    }
    .text-send {
      margin-bottom: 0 !important;
      width: 100% !important;
      height: 100% !important;
      input {
        width: 100% !important;
        height: 150px !important;
        padding: 0 !important;
      }
    }
    br {
      display: none;
    }
  }
`;


const ContactUs_Mutation = gql`
  mutation ContactUs(
    $Subject: String,
    $FullName: String,
    $Mobile: String,
    $Email: String,
    $Description: String,
  ) {
    contactus(contactUsForm: {
      Subject: $Subject,
      FullName: $FullName,
      Mobile: $Mobile,
      Email: $Email,
      Description: $Description,
    }) {
      status
      data
    }
  }
`;



function FormContactUs(props) {
  const [subject, setSubject] = useState("");
  const [names, setNames] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");
  
  const [ContactUsSend, contactUsSendState] = useMutation(ContactUs_Mutation,{
    onCompleted: updateDataAdd,
    fetchPolicy: 'network-only'
  });

  function updateDataAdd (data) {
    console.log(data);
      if(data.contactus.status){
          props.updateError({
              text: data.contactus.data,
              time: 3,
              type: 'confirm'
          });
          setDesc('');
          setEmail('');
          setMobile('');
          setSubject('');
          setNames('');
      } else {
        // error
        props.updateError({
            text: data.contactus.data,
            time: 3,
            type: 'error'
        });
      }
  }

  function sendData() {
    // send updated podcast data
    ContactUsSend({
      variables: {
        Subject: subject,
        FullName: names,
        Mobile: mobile,
        Email: email,
        Description: desc,
      },
    });
  }


  onchange = (e) => {
    let value = e.target.value;
    switch (e.target.name) {
      case "Subject":
        setSubject(value);
        break;
      case "Names":
        setNames(value);
        break;
      case "Mobile":
        setMobile(value);
        break;
      case "Email":
        setEmail(value);
        break;
      case "Desc":
        setDesc(value);
        break;
      default:
        break;
    }
  };

  const checkData = () => {
    if (
      (email || mobile) &&
      subject &&
      names &&
      desc
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Form className={"formcontact-"+Language[lang].dir}>
      <p className="titlecontantus"> {Language[lang].cFormTitle} </p>
      <div className="filds">
        <div className="fild-input">
          <TextInputWTitle
            ///in margin baraye ine ke dar size kamtar >matn error be btn nechasbe
            style={{ marginBottom: "0.5rem" }}
            name={"Subject"}
            value={subject}
            onchange={onchange}
            error={""}
            title={Language[lang].cFormSubject}
          />
          {/* <input placeholder="موضوع پیام" /> */}
        </div>
        <div className="fild-input">
          <TextInputWTitle
            ///in margin baraye ine ke dar size kamtar >matn error be btn nechasbe
            style={{ marginBottom: "0.5rem" }}
            name={"Names"}
            value={names}
            onchange={onchange}
            error={""}
            title={Language[lang].cFormName}
          />
          {/* <input placeholder="نام و نام خانوادگی" /> */}
        </div>
      </div>
      <div className="filds">
        <div className="fild-input">
          <TextInputWTitle
            ///in margin baraye ine ke dar size kamtar >matn error be btn nechasbe
            style={{ marginBottom: "0.5rem" }}
            name={"Mobile"}
            value={mobile}
            onchange={onchange}
            error={""}
            title={Language[lang].cFormMobile}
          />
          {/* <input placeholder="شماره تلفن همراه" /> */}
        </div>
        <div className="fild-input">
          <TextInputWTitle
            ///in margin baraye ine ke dar size kamtar >matn error be btn nechasbe
            style={{ marginBottom: "0.5rem" }}
            name={"Email"}
            value={email}
            onchange={onchange}
            error={""}
            title={Language[lang].cFormEmail}
          />
          {/* <input placeholder="ادرس ایمیل" /> */}
        </div>
      </div>
      <div className="fildtextsend">
        <div className="fild-input  text-send ">
          <div className="fild-input">
            <TextAreaWTitle
              ///in margin baraye ine ke dar size kamtar >matn error be btn nechasbe
              style={{ marginBottom: "0.5rem" }}
              name={"Desc"}
              value={desc}
              onchange={onchange}
              error={""}
              lines={5}
              title={Language[lang].cFormDesc}
            />
          </div>
        </div>
      </div>
      <div className="filds-footer">
        {/* <div className="desc">
          لطفاً پیش از ارسال ایمیل یا تماس تلفنی، ابتدا &nbsp;
          <span> پرسش‌‌های متداول </span>
          &nbsp; را مشاهده کنید .
        </div> */}
        <div className="button">
          <TextBtn
            type={"button"}
            text={Language[lang].cFormSend}
            onclick={sendData}
            loading={contactUsSendState.loading}
            disabled={!checkData() || contactUsSendState.loading}
          />
        </div>
        
        {/* <button>ارسال پیام</button> */}
      </div>
    </Form>
  );
}

export default FormContactUs;
