import React from "react";
export const IconComment = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3346 8.33366V15.1336C29.3346 16.827 28.7746 18.2536 27.7746 19.2403C26.788 20.2403 25.3613 20.8003 23.668 20.8003V23.2136C23.668 24.1203 22.6546 24.667 21.908 24.1603L20.6146 23.307C20.7346 22.8936 20.788 22.4403 20.788 21.9603V16.5337C20.788 13.8137 18.9746 12.0003 16.2546 12.0003H7.20129C7.01463 12.0003 6.8413 12.0137 6.66797 12.027V8.33366C6.66797 4.93366 8.93464 2.66699 12.3346 2.66699H23.668C27.068 2.66699 29.3346 4.93366 29.3346 8.33366Z"
        stroke="#E5E7EB"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.788 16.5334V21.96C20.788 22.44 20.7346 22.8933 20.6146 23.3066C20.1213 25.2666 18.4946 26.4933 16.2546 26.4933H12.628L8.6013 29.1733C8.0013 29.5867 7.20129 29.1467 7.20129 28.4267V26.4933C5.84129 26.4933 4.70797 26.04 3.92131 25.2533C3.12131 24.4533 2.66797 23.32 2.66797 21.96V16.5334C2.66797 14 4.2413 12.2534 6.66797 12.0267C6.8413 12.0134 7.01463 12 7.20129 12H16.2546C18.9746 12 20.788 13.8134 20.788 16.5334Z"
        stroke="#E5E7EB"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
