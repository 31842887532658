import React from "react";
export const IconLike = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8319 27.7471C16.3785 27.9071 15.6319 27.9071 15.1785 27.7471C11.3119 26.4271 2.67188 20.9205 2.67188 11.5871C2.67188 7.46712 5.99188 4.13379 10.0852 4.13379C12.5119 4.13379 14.6585 5.30712 16.0052 7.12046C17.3519 5.30712 19.5119 4.13379 21.9252 4.13379C26.0185 4.13379 29.3385 7.46712 29.3385 11.5871C29.3385 20.9205 20.6985 26.4271 16.8319 27.7471Z"
        stroke="#E5E7EB"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
