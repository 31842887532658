import React from "react";
import styled from "styled-components";
import { IconInstagram } from "../../Assets/Icons/IconInstagram";
import { IconLinkdin } from "../../Assets/Icons/IconLinkdin";
import { IconTwitter } from "../../Assets/Icons/IconTwitter";
import { LogoPodkadeh } from "../../Assets/LogoPodkadeh";

const Styles = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  padding: 1.5rem 0 1.5rem;
  --fs-title: 34px;
  --fs-svg: 56px;
  @media screen and (max-width: 1750px) {
    --fs-title: 29px;
    --fs-svg: 48px;
  }
  @media screen and (max-width: 1500px) {
    --fs-title: 24px;
    --fs-svg: 40px;
  }
  @media screen and (max-width: 1250px) {
    --fs-title: 20px;
    --fs-svg: 32px;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    svg {
      border: 3px solid var(--n-text-6);
      border-radius: 400px;
      box-shadow: var(--n-shadow-1);
      background-color: var(--n-text-6);
      width: var(--fs-svg);
      height: var(--fs-svg);
      transition: all 0.2s ease-in-out;
      position: relative;
      top: 0;
      left: 0;
      :hover {
        box-shadow: var(--n-shadow-1-hover);
        top: 2.5px;
        left: 2.5px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    padding: 1.5rem 0 2.5rem;
    .left {
      gap: 20px;
    }
  }
`;

export const NavPodkadehSocial2 = ({ style, className }) => {
  return (
    <Styles style={style} className={className}>
      <div className="left">
        <a href="https://twitter.com/podkadeh">
          <IconTwitter className={"icon-sochial-footer"} />
        </a>
        <a href="https://www.linkedin.com/company/podkadeh">
          <IconLinkdin className={"icon-sochial-footer"} />
        </a>
        <a href="https://www.instagram.com/podkadeh">
          <IconInstagram className={"icon-sochial-footer"} />
        </a>
      </div>
    </Styles>
  );
};
