import React from "react";
export const IconBookMark = ({ stroke, style, className }) => {
  return (
    /////////stroke by props
    <svg
      style={style}
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.75 15.9746H14.25"
        stroke={"#E5E7EB"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 12.3154V19.8154"
        stroke={"#E5E7EB"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.2305 3H10.7705C7.57547 3 4.98047 5.61 4.98047 8.79V29.925C4.98047 32.625 6.91547 33.765 9.28547 32.46L16.6055 28.395C17.3855 27.96 18.6455 27.96 19.4105 28.395L26.7305 32.46C29.1005 33.78 31.0355 32.64 31.0355 29.925V8.79C31.0205 5.61 28.4255 3 25.2305 3Z"
        stroke={"#E5E7EB"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
