import React from "react";
import styled from "styled-components";
import { IconInstagram } from "../../Assets/Icons/IconInstagram";
import { IconLinkdin } from "../../Assets/Icons/IconLinkdin";
import { IconTwitter } from "../../Assets/Icons/IconTwitter";
import { LogoPodkadeh } from "../../Assets/LogoPodkadeh";

const Styles = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 60px;
  gap: 6px;
  padding: 2rem 0;
  --anim-time-1: 0.5s;
  --anim-delay-1: 0.075s;
  .line  {
    height: 9px;
    width: 0%;
    border-radius: 100px;
    background-color: var(--n-text-6);
    animation: line-fill-2 var(--anim-time-1) 1 forwards;
  }
  .li-1 {
    animation-delay: calc(0 * var(--anim-delay-1));
  }
  .li-2 {
    animation-delay: calc(1 * var(--anim-delay-1));
  }
  .li-3 {
    animation-delay: calc(2 * var(--anim-delay-1));
  }
  .li-4 {
    animation-delay: calc(3 * var(--anim-delay-1));
  }
  .li-5 {
    animation-delay: calc(4 * var(--anim-delay-1));
  }
  .li-6 {
    animation-delay: calc(5 * var(--anim-delay-1));
  }
  .li-7 {
    animation-delay: calc(6 * var(--anim-delay-1));
  }
  .li-8 {
    animation-delay: calc(7 * var(--anim-delay-1));
  }
  .li-9 {
    animation-delay: calc(8 * var(--anim-delay-1));
  }
  @keyframes line-fill-2 {
        0% {
            width: 0%;
        }
        100% {
            width: 100%;
        }
    }
`;

export const BookSeam = ({ style, className }) => {
  return (
    <Styles style={style} className={className}>
      <div className="line li-1"></div>
      <div className="line li-2"></div>
      <div className="line li-3"></div>
      <div className="line li-4"></div>
      <div className="line li-5"></div>
      <div className="line li-6"></div>
      <div className="line li-7"></div>
      <div className="line li-8"></div>
      <div className="line li-9"></div>
    </Styles>
  );
};
