import React from "react";
export const IconProfileBlog = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#F3F2F1" />
    </svg>
  );
};
