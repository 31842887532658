import React from "react";

const IconDown = ({ style, className, onClick }) => {
  // style={style}
  return (
    <svg
      className={className}
      style={style}
      width="22"
      height="11"
      viewBox="0 0 22 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9006 1.25019L12.7506 9.40019C11.7881 10.3627 10.2131 10.3627 9.25059 9.40019L1.10059 1.25019"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconDown;
