import React, { useRef } from "react";
import styled from "styled-components";
import { Language } from "../../Language/Language";
import { lang } from "../../App";
import Img1 from "../../Assets/ads/img1.png";
const Style = styled.div`
    margin-top: 12rem;
    margin-bottom: 4rem;
    grid-column: 2/14;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    position: relative;
    .box {
        width: calc(100% - 3rem - 6px);
        flex-grow: 1;
        border: 3px solid var(--n-text-6);
        border-radius: 4px;
        box-shadow: var(--n-shadow-1);
        background: #fff;
        margin-top: -2rem;
        padding: calc(2rem - 1px) 1.5rem 3rem 1.5rem;
        display: flex;
        flex-direction: row-reverse;
        gap: 1rem;
        justify-content: space-between;
        .image {
            z-index: 1;
            margin-top: -8rem;
            margin-bottom: -8rem;
            width: calc(50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            img {
                z-index: 2;
                width: 100%;
                border-radius: 4px;
            }
            .shadow {
                z-index: 1;
                background-color: #000000;
                border-radius: 1000px;
                width: 80%;
                padding-top: 80%;
                position: absolute;
                bottom: -0.5rem;
                box-shadow: none;
                transform-origin: bottom;
                opacity: 0.3;
                transform: scale(1, 0.07);
            }
        }
        .data {
            z-index: 1;
            width: calc(50%);
            display: flex;
            align-items: start;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            .title {
                font-size: 48px;
                font-weight: 900;
            }
            .desc {
                font-size: 20px;
                font-weight: 500;
                color: var(--n-grey-7)
            }
        }
    }
    @media screen and (max-width: 1000px) {
        margin-top: 5rem;
        margin-bottom: 1rem;
        grid-column: 1/15;
        padding: 8rem 1rem 5rem;
        overflow: hidden;
        .box {
            flex-direction: column-reverse;
            gap: 1rem;
            width: calc(100% - 2rem - 6px);
            padding: calc(2rem - 1px) 1rem 3rem 1rem;
            .data {
                width: calc(100%);
            }
            .image {
                z-index: 1;
                margin-top: -8rem;
                margin-bottom: 0;
                width: calc(100%);
                img {
                    width: 90%;
                }
            }
        }

    }
`
export default function Section1(props) {
    const ref1 = useRef();
    return (
        <Style ref={ref1} className={"home3-"+Language[lang].dir}>
            {/* <div className={"circle-back1"}></div> */}
            <div className="box">
                {/* <img className="lines-top" src={linesTop} alt="lines"/> */}
                <div className="data">
                    <div className="title">
                        Dynamic Ads
                    </div>
                    <div className="desc">
                    Podkadeh unlocks the untapped revenue
                    potential of podcasting.  Our proprietary
                    dynamic ad insertion technology empowers
                    podcast hosting services to deliver targeted
                    advertising experiences that maximize
                    revenue for platforms and advertisers alike.
                    </div>
                </div>
                <div className="image">
                    <img src={Img1} alt="besaz"/>
                </div>
            </div>
        </Style>
    )
}