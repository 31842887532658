import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IconLike } from "../../Assets/Icons/IconLike";
import { IconReplay } from "../../Assets/Icons/IconReplay";
import { IconUserCommentBlog } from "../../Assets/Icons/IconUserCommentBlog";
const Style = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  width: 100%;
  margin-bottom: 40px;
  .icon-cm {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 32px !important;
  }
  .icon-margin-g-c {
    margin-right: 44px;
  }
  p {
    
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #b3b3b3;
  }
  desc {
    
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #b3b3b3;
    margin-bottom: 40px;
  }
  .header-cm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
  }
  .profile-cm {
    display: flex;
    align-items: center;
    svg {
      margin-left: 6px;
    }
  }
  .clander-release-style {
    margin-left: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1300px) {
      margin-left: 300px;
    }
    @media screen and (max-width: 1100px) {
      margin-left: 200px;
    }
    @media screen and (max-width: 550px) {
      margin-left: 70px;
    }
    @media screen and (max-width: 400px) {
      margin-left: 10px;
    }
  }
  @media screen and (max-width: 1000px) {
    .icon-margin-g-c {
      margin-right: 22px;
    }
    .icon-cm svg {
      width: 24px;
    }
  }
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    width: 100%;
    margin-bottom: 38px;
    .icon-margin-g-c {
      margin-right: 15px;
    }
    p {
      
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #b3b3b3;
    }
    desc {
      
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #b3b3b3;
      margin-bottom: 20px;
    }
    .header-cm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .profile-cm {
      display: flex;
      align-items: center;
      svg {
        margin-left: 6px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    p {
      font-size: 14px;
    }
  }
`;
export default function BlogComment({ className, style }) {
  return (
    <Style style={style} className={className}>
      <div className="header-cm">
        <div className="profile-cm">
          <Link to="/">
            <IconUserCommentBlog />
          </Link>
          <p>نام کاربر</p>
        </div>
        <p className="clander-release-style">تاریخ انتشار</p>
        <div className="icon-cm">
          <Link to="/">
            <IconReplay />
          </Link>
          <Link to="/">
            <IconLike className={"icon-margin-g-c"} />
          </Link>
        </div>
      </div>
      <desc>
        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده
        از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و
        سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای
        متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه
        درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد
      </desc>
      <div className="border-x" />
    </Style>
  );
}
