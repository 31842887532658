import React, { useRef } from "react";
import styled from "styled-components";
import Questions from "../Components/Question/Questions";
import MainLayout from "./Layout/MainLayout";
import backcontactcon from "../Assets/Images/ContactBackground.png";
import imgincontentcontact from "../Assets/Images/imgincontentcontact.png";
import FormContactUs from "../Components/ContactUs/FormContactUs";
import IconDown from "../Assets/Icons/IconDown";
import googlemap from "../Assets/Images/googlemap.png";
import { IconCall } from "../Assets/Icons/IconCall";
import { IconFax } from "../Assets/Icons/IconFax";
import { IconEmail } from "../Assets/Icons/IconEmail";
import { IconLocation } from "../Assets/Icons/IconLocation";
import backcontactconresponsive from "../Assets/Images/backcontactconresponsive.png";
import phone1 from "../Assets/new1/phone2.svg"
import { NavPodkadehSochial } from "../Components/sochial/NavPodkadehSochial";
import { NavPodkadehSocial2 } from "../Components/sochial/NavPodkadehSocial2";
import { LinesOne } from "../Components/Lines/LinesOne";
import { BookSeam } from "../Components/Lines/BookSeam";
import { BookSeamHorizontal } from "../Components/Lines/BookSeamHorizontal";
import { Language } from "../Language/Language";
import { lang } from "../App";
const Style = styled.div`
  margin-top: 60px !important;
  width: 100%;
  height: 100%;
  background: none;
  position: relative;
  &.contactus-ltr {
    direction: rtl;
  }
  .container-contactus {
    margin-bottom: 136px;
    display: grid;
    grid-template-columns:
      6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
      6.09%;
    grid-column-gap: 1.56%;
    grid-template-rows: calc(100vh - 68px);
    align-content: flex-start;
    justify-content: center;
    position: relative;
  }
  .content-contactus {
    margin: 30px 0 30px;
    grid-column: 2/14;
    display: flex;
    gap: 1rem;
    /* overflow: hidden; */
    position: relative;
    border-radius: 0;
    .img-in-content-contact {
      width: calc(50% - calc(0.5rem + 6px));
      height: calc(100% - calc(3rem + 6px));
      z-index: 100;
      border: 3px solid var(--n-text-6);
      border-radius: 4px;
      box-shadow: var(--n-shadow-1);
      background-color: #ADD0D8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 3rem 0.5rem 0;
      position: relative;
      img {
        position: relative;
        width: 60%;
        /* height: 80%; */
        margin: auto;
        object-fit: fill;
        animation: phone-anim 1s 1 forwards ease-in-out;
      }
      .lines {
        position: absolute;
      }
      .top {
        top: 1rem;
        flex-direction: column;
      }
      .bottom {
        bottom: 1rem;
        flex-direction: column-reverse;
      }
      .left {
        left: 1rem;
        align-items: flex-end;
        .line {
          transform-origin: left;
        }
      }
      .right {
        right: 1rem;
        align-items: flex-start;
        .line {
          transform-origin: right;
        }
      }
    }
    .formcontant {
      width: calc(50% - calc(0.5rem + 6px));
      height: calc(100% - calc(4rem + 6px));
      z-index: 100;
      border: 3px solid var(--n-text-6);
      border-radius: 4px;
      box-shadow: var(--n-shadow-1);
      background: var(--n-grey-05);
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 2rem 0.5rem 2rem;
    }
    .center-seam {
      position: absolute;
      z-index: 100;
      top: 0;
      left: calc(50% - 30px);
      bottom: 0;
    }
    .center-seam-h {
      display: none;
    }
  }
  @keyframes phone-anim {
    0% {
        transform: rotate(0) scale(1)
    }
    10% {
        transform: rotate(-15deg) scale(1)
    }
    20% {
        transform: rotate(15deg) scale(1)
    }
    30% {
        transform: rotate(-15deg) scale(1)
    }
    40% {
        transform: rotate(15deg) scale(1)
    }
    50% {
        transform: rotate(0) scale(1)
    }
    100% {
        transform: rotate(0) scale(1)
    }
  }
  @media screen and (max-width: 1000px) {
    margin-top: 55px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    .container-contactus {
      grid-template-columns: 4.09% repeat(12, calc(calc(88.7% / 12) - calc(17.16% / 12))) 4.09%;
      grid-template-rows: 1fr;
      margin-bottom: 50px;
    }
    .content-contactus {
      flex-direction: column;
      gap: 0.5rem;
      .img-in-content-contact {
        width: calc(100% - calc(1rem + 6px));
        img {
          width: 75%;
        }
      }
      .formcontant {
        width: calc(100% - calc(1rem + 6px));
        height: calc(100% - calc(2rem + 6px));
        padding: 2rem 0.5rem 0rem;
      }
      .center-seam {
        display: none;
      }
      .center-two {
        z-index: 101;
        position: relative;
      }
      .center-seam-h {
        display: flex;
        position: absolute;
        z-index: 100;
        top: calc(-20px);
        left: 0;
        bottom: 0;
      }
    }
  }
`;
export default function ContactUs(props) {
  const ref1 = useRef();
  const moveDown = (e) => {
    console.log(ref1.current)
    window.scrollTo({
      top: ref1.current.offsetHeight + 70,
      behavior: "smooth",
    })
  }
  return (
    <MainLayout {...props}>
      <Style className={"contactus-"+Language[lang].dir}>
        <div className="container-contactus">
          <div className="content-contactus">
            <div className="img-in-content-contact">
              <img src={phone1} alt="imgincontentcontact" />
              <div className="socials">
                <NavPodkadehSocial2 />
              </div>
              <LinesOne className="lines top left"/>
              <LinesOne className="lines top right"/>
              <LinesOne className="lines bottom left"/>
              <LinesOne className="lines bottom right"/>
            </div>
            <div className="center-two">
            <BookSeamHorizontal className={"center-seam-h"}/>
            </div>
            <div className="formcontant">
              <FormContactUs {...props}/>
            </div>
            <BookSeam className={"center-seam"}/>
          </div>
        </div>
      </Style>
    </MainLayout>
  );
  // if (window.innerWidth > 1000) {
    
  // } else {
  //   return (
  //     <MainLayout {...props}>
  //       <Style>
  //         <div className="imgback">
  //           <img
  //             src={backcontactconresponsive}
  //             alt="backcontactconresponsive"
  //           />
  //         </div>
  //         <div className="container-contactus">
  //           <div className="content-contactus">
  //             <div className="formcontant">
  //               <FormContactUs />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="contactus">
  //           <div className="img-content-contact">
  //             <img src={googlemap} alt="mapcontactus" />
  //           </div>
  //           <div className="content-contactus">
  //             <p className="title-contact">تماس با پادکده</p>
  //             <div className="border-x" />
  //             <div className="detail-contactus">
  //               <div className="details">
  //                 <p>تلفن ثابت</p>
  //                 <div className="iconanddetail">
  //                   021.88556633
  //                   <IconCall />
  //                 </div>
  //               </div>
  //               <div className="details">
  //                 <p>فکس</p>
  //                 <div className="iconanddetail">
  //                   021.88556633
  //                   <IconFax />
  //                 </div>
  //               </div>
  //               <div className="details">
  //                 <p>ایمیل</p>
  //                 <div className="iconanddetail">
  //                   Podkadeh@Gmail.com
  //                   <IconEmail />
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="podkadehloc">
  //               <IconLocation />

  //               <p>
  //                 خیابان شریعتی نریسیده به طالقانی تقاطع بهار قبل میثاق 5 پلاک
  //                 297 واحد سه
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="grid-questions">
  //           <div className="questionscontactus">
  //             <Questions className={"questions-cu-component"} showmore={true}/>
  //           </div>
  //         </div>
  //       </Style>
  //     </MainLayout>
  //   );
  // }
}
