import React from "react";
export const IconSendCm = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4888 19.7706L5.92876 15.4906C2.08876 13.5706 2.08876 10.4306 5.92876 8.5106L14.4888 4.2306C20.2488 1.3506 22.5988 3.7106 19.7188 9.4606L18.8488 11.1906C18.6288 11.6306 18.6288 12.3606 18.8488 12.8006L19.7188 14.5406C22.5988 20.2906 20.2388 22.6506 14.4888 19.7706Z"
        stroke="#C4C4C4"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5586 12L13.1586 12"
        stroke="#C4C4C4"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
