import React from "react";
import MainLayout from "./Layout/MainLayout";
import styled from "styled-components";
import BlogComponents from "../Components/Blog/BlogComponent";
const Styles = styled.div`
  width: 100%;
  height: 100%;
  margin: 5rem 0 4rem;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  grid-template-rows: 500px 1fr;
  .headerblog {
    grid-row: 1/2;
    grid-column: 1/15;
    height: 660px;
    height: 100%;
    background: #e1e0e0;
    display: flex;
    
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 81px;
    align-items: center;
    justify-content: center;
  }
  .blogs {
    grid-column: 2/14;
    grid-row: 2/3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 5rem;
  }
  .blog-component-page {
    background: #ffffff;
    border-radius: 5px;
    margin: 0 28px 40px;
    .title-content {
      font-style: normal;
      font-weight: 400;
      margin-right: 12px;
      font-size: 22px;
      color: #323130;
      line-height: 27px;
    }
    .desc {
      font-style: normal;
      font-weight: 400;
      margin-right: 12px;
      width: 95% !important;
      color: #323130;
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 14px;
    }
    .img {
      img {
        border-radius: 0 !important;
      }
    }
  }
  @media screen and (max-width: 1250px) {
    width: 100%;
    height: 100%;
    margin: 3rem 0 4rem;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 1fr 30px;
    grid-template-rows: 500px 1fr;
    .headerblog {
      grid-row: 1/2;
      grid-column: 1/6;
      height: 400px;
      height: 100%;
      background: #e1e0e0;
      
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 61px;
    }
    .blogs {
      grid-column: 2/5;
      grid-row: 2/3;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 5rem;
      color: var(--text-2);
    }
    .blog-component-page {
      margin: 0 12px 40px;
      border-radius: 5px;
      .title-content {
        margin-right: 18px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px !important;
        line-height: 27px;
      }
      .desc {
        margin-right: 18px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px !important;
        margin-bottom: 16px;
        line-height: 27px;
        width: 90%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    margin: 3rem 0 4rem;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 1fr 30px;
    grid-template-rows: 500px 1fr;
    .headerblog {
      grid-row: 1/2;
      grid-column: 1/6;
      height: 200px;
      height: 100%;
      background: #e1e0e0;
      
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 61px;
    }
    .blogs {
      grid-column: 2/5;
      grid-row: 2/3;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 5rem;
      color: var(--text-2);
    }
    .blog-component-page {
      margin: 0 8px 24px !important;
      border-radius: 0;
      .title-content {
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 27px;
        margin-right: 13px;
      }
      .desc {
        font-style: normal;
        font-weight: 400;
        font-size: 8px !important;
        margin-bottom: 8px;
        margin-right: 13px;
        width: 90% !important;
        line-height: 27px;
      }
    }
  }
`;
export const Blogs = ({ props }) => {
  return (
    <MainLayout {...props}>
      <Styles>
        <div className="headerblog"> وبلاگ پادکده </div>
        <div className="blogs">
          <BlogComponents
            className={"blog-component-page"}
            title={"عنوان متن"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
          <BlogComponents
            className={"blog-component-page"}
            title={"عنوان متن"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
          <BlogComponents
            className={"blog-component-page"}
            title={"عنوان متن"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
          <BlogComponents
            title={"عنوان متن"}
            className={"blog-component-page"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
          <BlogComponents
            className={"blog-component-page"}
            title={"عنوان متن"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
          <BlogComponents
            className={"blog-component-page"}
            title={"عنوان متن"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
          <BlogComponents
            className={"blog-component-page"}
            title={"عنوان متن"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
          <BlogComponents
            className={"blog-component-page"}
            title={"عنوان متن"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
          <BlogComponents
            className={"blog-component-page"}
            title={"عنوان متن"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
          <BlogComponents
            className={"blog-component-page"}
            title={"عنوان متن"}
            text={
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"
            }
          />
        </div>
      </Styles>
    </MainLayout>
  );
};
