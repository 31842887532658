import React, { useRef } from "react";
import styled from "styled-components";
import MainLayout from "./Layout/MainLayout";
import IconDown from "../Assets/Icons/IconDown";
import landingImage from '../Assets/Images/landing-svg.svg'


const LandingComponents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 60px;
  --fs-text-title: 64px;
  --fs-text-subtitle: 36px;
  --fs-text-desc: 20px;
  --fs-text-button: 18px;
  --fs-text-down: 16px;
  --fs-slide-title: 40px;
  --fs-slide-desc: 24px;
  --fs-slide-btn: 16px;
  --cl-btn-1-main: #CCB3E5;
  --cl-btn-1-back: #CCB3E515;
  --cl-btn-2-main: #FFC299;
  --cl-btn-2-back: #FFC29915;
  --cl-btn-3-main: #BFEB93;
  --cl-btn-3-back: #BFEB9315;
  @media (max-width: 1750px) {
    --fs-text-title: 56px;
    --fs-text-subtitle: 32px;
    --fs-text-desc: 17px;
    --fs-text-button: 16px;
    --fs-text-down: 14px;
    --fs-slide-title: 34px;
    --fs-slide-desc: 20px;
    --fs-slide-btn: 15px;
  }
  @media (max-width: 1500px) {
    --fs-text-title: 48px;
    --fs-text-subtitle: 28px;
    --fs-text-desc: 14px;
    --fs-text-button: 14px;
    --fs-text-down: 13px;
    --fs-slide-title: 28px;
    --fs-slide-desc: 16px;
    --fs-slide-btn: 14px;
  }
  @media (max-width: 1250px) {
    --fs-text-title: 40px;
    --fs-text-subtitle: 24px;
    --fs-text-desc: 13px;
    --fs-text-button: 13px;
    --fs-text-down: 12px;
    --fs-slide-title: 22px;
    --fs-slide-desc: 24px;
    --fs-slide-btn: 13px;
  }
  @media (max-width: 1000px) {
    --fs-text-title: 32px;
    --fs-text-subtitle: 24px;
    --fs-text-desc: 16px;
    --fs-text-button: 18px;
    --fs-text-down: 14px;
    --fs-slide-title: 40px;
    --fs-slide-desc: 24px;
    --fs-slide-btn: 18px;
  }
  @media (max-width: 600px) {
    --fs-text-title: 24px;
    --fs-text-subtitle: 16px;
    --fs-text-desc: 14px;
    --fs-text-button: 16px;
    --fs-text-down: 12px;
    --fs-slide-title: 24px;
    --fs-slide-desc: 16px;
    --fs-slide-btn: 16px;
  }
  .main-grid {
    display: grid;
    grid-template-columns:
      6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
      6.09%;
    grid-column-gap: 1.56%;
  }
  .content-left {
    z-index: 3;
    grid-column: 9/14;
    margin: auto 0;
    display: flex;
    flex-direction: column;
  }
  .button {
    cursor: pointer;
    text-decoration: none;
    width: 40%;
    background: var(--text-4);
    padding: 15px 30px;
    text-align: center;
    
    font-size: var(--fs-text-button);
    color: var(--text-1);
    font-weight: bold;
    text-align: center;
    border-radius: 8px;
  }
  .btn-1 {
    color: var(--cl-btn-1-main);
    border: solid 1px var(--cl-btn-1-main);
    background-color: var(--cl-btn-1-back);
    font-size: var(--fs-slide-btn);
  }
  .btn-2 {
    color: var(--cl-btn-2-main);
    border: solid 1px var(--cl-btn-2-main);
    background-color: var(--cl-btn-2-back);
    font-size: var(--fs-slide-btn);
  }
  .btn-3 {
    width: 30%;
    color: #000000;
    border: solid 1px var(--cl-btn-3-main);
    background-color: var(--cl-btn-3-main);
    font-size: var(--fs-slide-btn);
  }

  .grad-1 {
    background: linear-gradient(45deg, #3C096C, #8040BF);;
  }
  .grad-2 {
    background: linear-gradient(45deg, #117C23, #66CC00);;
  }
  .grad-3 {
    background: linear-gradient(45deg, #F4491D, #FF6700);;
  }


  .top-slide {
    background: linear-gradient(
      180deg,
      #424242 0%,
      #151515 100%
    );
    position: relative;
    margin-bottom: 70px;
    .back-image {
      z-index: 1;
      grid-column: 1/9;
      img {
        width: 100%;
        object-fit: cover;
        display: block;
      }
    }
    .title {
      font-size: var(--fs-text-title) !important;
      font-weight: 500;
      margin: 0.5rem 0;
      color: var(--text-title-landing);
    }
    .subtitle {
      font-size: var(--fs-text-subtitle) !important;
      font-weight: normal;
      margin: 0.5rem 0;
      color: var(--text-1);
    }
    .desc {
      font-size: var(--fs-text-desc) !important;
      font-weight: normal;
      margin: 0.5rem 0;
      color: var(--text-1);
    }
    .button-more {
      margin: 3rem 0 0;
      width: fit-content;
      color: var(--primary-3);
      
      @media (max-width: 1000px) {
        margin: 1rem 0 0;
        width: calc(100% - 4rem);
      }
    }
    .know-us {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .know-us-in {
        border-radius: 16px 16px 0px 0px;
        backdrop-filter: blur(30px);
        background: rgba(255, 255, 255, 0.1);
        padding: 0.5rem 1rem;
        font-weight: 500;
        font-size: var(--fs-text-down);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-1);
        text-decoration: none;
        svg {
          margin-right: 10px;
          width: 1rem;
        }
      }
    }
  }
  .normal-slide {
    margin: 70px 0;
    background-color: var(--primary-1);
    .video {
      margin-top: -32px;
      .dumy-vid{
        border-radius: 32px;
        overflow: hidden;
        border: solid 1px var(--text-6);
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 2rem 0;
      .title {
        font-size: var(--fs-slide-title);
        font-weight: bold;
        margin: 0.5rem 0;
      }
      .desc {
        font-size: var(--fs-slide-desc);
        margin: 0.5rem 0;
        color: var(--text-2);
        text-align: justify;
      }
      .link-btn {
        font-size: var(--fs-slide-btn);
        margin: 1.5rem 0;
      }
      .go-down-action {
        margin: 1.5rem 0;
        color: var(--text-4);
        cursor: pointer;
        .icon-action {
          margin: 0 0.3rem;
          position: relative;
          top: 0.1rem;
          stroke: var(--text-4);
          width: 1rem;
        }
      }

    }
    &.right {
      .video {
        grid-column: 2/8;
      }
      .content {
        grid-column: 9/14;
      }
    }
    &.left {
      .video {
        grid-column: 8/14;
      }
      .content {
        grid-column: 2/7;
      }
    }
    &.center {
      margin: 20% 0 70px;
      .video {
        grid-column: 4/12;
        padding-top: 56.25%;
        position: relative;
        margin-top: -25%;
        .dumy-vid{
          position: absolute;
          top: 0;
        }
      }
      .content {
        grid-column: 3/13;
      }
    }
  }

  .r1_iframe_embed {
    position: relative; overflow: hidden; width: 100%; height: auto; padding-top: 56.25%;
  } 
  .r1_iframe_embed iframe { 
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0; 
  }



  @media (max-width: 1000px) {
    margin-top: 55px;
    .main-grid {
      grid-template-columns:
        14px repeat(6, calc(calc(calc(100% - 60px) / 6) - calc(80px / 6)))
        14px;
      grid-column-gap: 16px;
    }
    .top-slide {
      .back-image {
        grid-column: 1/9;
      }
    }
    .button {
      width: calc(100% - 4rem);
    }
    .content-left {
      grid-column: 2/8;
      height: calc(100% - 7rem);
      justify-content: flex-end;
      margin: 2rem 0 5rem;
    }
    .normal-slide {
      margin: 20% 0 70px;
      .video {
        position: relative;
        padding-top: 56.25%;
        margin-top: -20%;
        .dumy-vid{
          position: absolute;
          top: 0;
        }
      }
      &.right {
        .video {
          grid-column: 2/8;
        }
        .content {
          grid-column: 2/8;
        }
      }
      &.left {
        .video {
          grid-column: 2/8;
        }
        .content {
          grid-column: 2/8;
        }
      }
      &.center {
        .video {
          grid-column: 2/8;
          margin-top: -20%;
        }
        .content {
          grid-column: 2/8;
        }
      }
    }
  }
  @media (max-width: 600px) {
    margin-top: 50px;
    .main-grid {
      grid-template-columns:
        8px repeat(4, calc(calc(calc(100% - 32px) / 4) - calc(24px / 4)))
        8px;
      grid-column-gap: 8px;
    }
    .button {
      width: calc(100% - 4rem);
    }
    .top-slide {
      .back-image {
        grid-column: 1/7;
      }
    }
    .content-left {
      grid-column: 2/6;
      /* height: calc(100% - 5rem);
      justify-content: flex-end;
      margin-bottom: 5rem; */
    }
    .normal-slide {
      margin: 20% 0 70px;
      .video {
        position: relative;
        border-radius: 16px;
        padding-top: 56.25%;
        margin-top: -20%;
        .dumy-vid{
          position: absolute;
          top: 0;
        }
      }
      &.right {
        .video {
          grid-column: 2/6;
        }
        .content {
          grid-column: 2/6;
        }
      }
      &.left {
        .video {
          grid-column: 2/6;
        }
        .content {
          grid-column: 2/6;
        }
      }
      &.center {
        .video {
          grid-column: 2/6;
          margin-top: -20%;
        }
        .content {
          grid-column: 2/6;
        }
      }
    }
  }
`;

function LandingPageV2(props) {
  const ref1 = useRef();
  const refs = [
    useRef(),
    useRef(),
    useRef(),
  ]
  // const ref3 = useRef();
  const moveDown = (e) => {
    console.log(ref1.current)
    window.scrollTo({
      top: ref1.current.offsetHeight + 70,
      behavior: "smooth",
    })
  }
  const makeGoDown = (i) => {
    console.log(refs[i].current.offsetHeight)
    let norm = 150;
    if(i === refs.length - 2){
      norm = 200;
    }
    window.scrollTo({
      top: window.scrollY + refs[i].current.offsetHeight + norm,
      behavior: "smooth",
    })
  }
  const makeGodown = (title, i) => {
    return (
      <div className={"go-down-action "} onClick={() => makeGoDown(i)}>
        {title}
        <IconDown className={'icon-action'} />
      </div>
    )
  }
  const makeSlides = () => {
    return data.map((val, index) => {
      return (
        <div ref={refs[index]} id={val.title} className={"normal-slide main-grid " + val.dir}>
          {(val.dir !== 'left' || window.innerWidth <= 1000) ? (
            <div className="video">
              <div className={"dumy-vid " + val.vidGrad}>
                {val.vid}
              </div>
            </div>
          ) : ''}
          <div className="content">
            <div className="title">
              {val.title}
            </div>
            <div className="desc">
              {val.desc}
            </div>
            
            {(val.linkType === 'godown') ? (
              makeGodown(val.linkTitle, index)
            ) : (
            <a className={"button link-btn " + val.linkClass} href={val.link}>
              {val.linkTitle}
            </a>
          )}
          </div>
          {(val.dir === 'left' && window.innerWidth > 1000) ? (
            <div className="video">
              <div className={"dumy-vid " + val.vidGrad}>
                {val.vid}
              </div>
            </div>
          ) : ''}
        </div>
      )
    })
  }
  return (
    <MainLayout {...props}>
      <LandingComponents>
        <div className="top-slide main-grid" ref={ref1}>
          <div className="back-image">
            <img src={landingImage} alt="imagecontainerlanding" />
          </div>
          <div className="content-left">
            <div className="title">
              پادکده
            </div>
            <div className="subtitle">
              پادکده بشنو، برای پادکسترها منتشر شد
            </div>
            <div className="desc">
              برای داشتن تجربه بهتر در اولین استفاده از اپلیکیشن پادکده، لطفا قبل از دانلود فید پادکست خود را برای ثبت در اپلیکیشن وارد کنید. منتظر نظرات ارزشمند شما هستیم.
            </div>
            <a href={'https://podkadeh.com/podcaster'} className="button button-more btn-3">
              ثبت فید برای دانلود بشنو
            </a>
            <div className="know-us">
              <div className="know-us-in" onClick={moveDown}>
                با ما بیشتر آشنا شوید
                <IconDown style={{ stroke: "#ffffff" }} />
              </div>
            </div>
          </div>
        </div>
        {makeSlides()}
      </LandingComponents>
    </MainLayout>
  )
}

const data = [
  {
    dir: 'right', 
    title: 'پادکده!', 
    desc: 'تو این ویدیو سپهر مختاری، مدیرعامل و هم‌بنیان‌گذار پادکده به طور کامل درباره پادکده، اهدافش و سرویس‌هایی که ارائه میده صحبت می‌کنه.', 
    link: '#پادکده!',
    linkType: 'godown',
    linkTitle: 'با بشنو آشنا شوید',
    linkClass: 'btn-1',
    vidGrad: 'grad-1',
    vid: (
      <div class="r1_iframe_embed"><iframe src="https://player.arvancloud.ir/index.html?config=https://podkadeh.arvanvod.ir/yOwGwm50Mm/N6bZY9ZBg5/origin_config.json" style={{border:"0 #ffffff none"}} title={"Sepehr intro final.mp4"} name="Sepehr intro final.mp4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>
    )
  },
  {
    dir: 'left', 
    title: 'بشنو!', 
    desc: 'تو این ویدیو ستایش حسینی از تیم فنی پادکده، درباره ویژگی‌های اصلی اپلیکیشن پادکده بشنو صحبت میکنه و توضیح میده که چطور این اپلیکیشن تمام نیازهای شنونده پادکست فارسی رو پوشش میده.', 
    linkType: 'godown',
    link: '#بشنو!',
    linkTitle: 'قدم اول را بردارید',
    linkClass: 'btn-2',
    vidGrad: 'grad-3',
    vid: (
      <div class="r1_iframe_embed"><iframe src="https://player.arvancloud.ir/index.html?config=https://podkadeh.arvanvod.ir/yOwGwm50Mm/eGrJDoKoan/origin_config.json" style={{border:"0 #ffffff none"}} title={"Seti finall.mp4"} name="Seti finall.mp4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>
    )
  },
  {
    dir: 'center', 
    title: 'قدم اول', 
    desc: 'برای اینکه بتونیم در کنار هم تجربه بهتری برای شنوندگان پادکست فارسی خلق کنیم، در قدم اول از شما دعوت می‌کنیم که نسخه بتای اپلیکیشن ما رو نصب و تست کنید. منتظر نظرات ارزشمند شما هستیم.', 
    link: 'https://podkadeh.com/podcaster',
    linkTitle: 'ثبت فید برای دانلود بشنو',
    linkClass: 'btn-3',
    vidGrad: 'grad-2',
    vid: (
      <div class="r1_iframe_embed"><iframe src="https://player.arvancloud.ir/index.html?config=https://podkadeh.arvanvod.ir/yOwGwm50Mm/JMr5pnaBRz/origin_config.json" style={{border:"0 #ffffff none"}} title={"erfan final.mp4"} name="erfan final.mp4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>
    )
  }
]





export default LandingPageV2;
