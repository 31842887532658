import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Blogs } from "./Pages/Blogs";
// import LandingPage from "./Pages/LandingPage";
import { BlogDetails } from "./Pages/BlogDetails";
import AboutUs from "./Pages/AboutUs";
import TermsOfService from "./Pages/TermsOfService";
import ContactUs from "./Pages/ContactUs";
import Sponsor from "./Pages/Sponsor";
import Besaz from "./Pages/Besaz";
import GoToTop from "./Components/Helper/GoToTop";
import LandingPageV2 from "./Pages/LandingPageV2";
// import CommingSoon from "./Pages/CommingSoon";
import PageTitles from "./Components/Helper/PageTitles";
import NotMatch from "./Pages/NotMatch";
import { ModalError } from "./Components/ModalError";
import HomeV2 from "./Pages/HomeV2";
import { Language } from "./Language/Language";
import DynamicAds from "./Pages/DynamicAds";


export const lang = "en"

function App() {
  const [errors, setErrors] = useState({});
  useEffect(() => {
    document.documentElement.style.setProperty("--direction", Language[lang].dir);
  })
  function updateError({ text, time, type }) {
    setErrors({
      text: text,
      time: time,
      type: type,
    });
  }
  return (
    <BrowserRouter>
      <GoToTop/>
      <PageTitles/>
      <Routes>
        {/* <Route path="/" element={<CommingSoon updateError={updateError}/>} /> */}
        <Route path="/" element={<HomeV2 updateError={updateError}/>} />
        <Route path="/dynamic-ads" element={<DynamicAds updateError={updateError}/>} />
        <Route path="/besaz" element={<Besaz updateError={updateError}/>} />
        <Route path="/besaz-landing" element={<LandingPageV2 updateError={updateError}/>} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/BlogDetails" element={<BlogDetails updateError={updateError}/>} />
        <Route path="/aboutus" element={<AboutUs updateError={updateError}/>} />
        <Route path="/termsofservice" element={<TermsOfService updateError={updateError}/>} />
        <Route path="/contactus" element={<ContactUs updateError={updateError}/>} />
        <Route path="/sponsor" element={<Sponsor updateError={updateError}/>} />
        <Route path="*" element={<NotMatch updateError={updateError}/>} />
      </Routes>
      <ModalError errors={errors} updateError={updateError} />
    </BrowserRouter>
  );
}

export default App;
