import styled from "styled-components";
import React from "react";
const Style = styled.div`
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  margin-top: 200px;
  margin-bottom: 500px;
  align-items: center;
  background: #201f1e;
  height: 700px;
  justify-content: center;
  .header-form-title {
    font-size: var(--fs--48-30-24);
    font-weight: 500 !important;
    line-height: 54px !important;
    letter-spacing: 0px;
    text-align: center;
    grid-column: 2/14;
  }
  .details-sponser-ad {
    display: flex;
    grid-column: 2/14;
    width: 100%;
    justify-content: space-around;
    .detail-sponser-ad {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 25%;

      .m-n {
        font-family: KalamehWebFaNum(FaNum);
        font-size: 64px;
        font-weight: 700;
        line-height: 109px;
        letter-spacing: 0em;
        text-align: center;
      }
      .l-n {
        font-family: KalamehWebFaNum(FaNum);
        font-size: 96px;
        font-weight: 700;
        line-height: 163px;
        letter-spacing: 0em;
        text-align: center;
      }
      .border-b {
        background: #e5e5e5;
        width: 100%;
        height: 1px;
        margin-top: 6px;
        margin-bottom: 22px;
      }
      .desc-detail {
        
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 41px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #e5e5e5;
      }
    }
  }
`;
function AdEffectiveness() {
  return (
    <Style>
      <p className="header-form-title">اثرگذاری تبلیغات در پادکست </p>
      <div className="details-sponser-ad">
        <div className="detail-sponser-ad">
          <p className="number-detail m-n ">29%</p>
          <div className="border-b" />
          <p className="desc-detail">
            شنوندگان محصول یا خدمت تبلیغ شده را خریداری می‌کنند.
          </p>
        </div>
        <div className="detail-sponser-ad">
          <p className="number-detail l-n ">53%</p>
          <div className="border-b" />
          <p className="desc-detail">
            شنوندگان درباره برند یا محصول تبلیغ شده، جستجوی آنلاین می‌کنند.
          </p>
        </div>
        <div className="detail-sponser-ad">
          <p className="number-detail m-n ">27%</p>
          <div className="border-b" />
          <p className="desc-detail">
            شنوندگان برند تبلیغ شده را در شبکه‌های اجتماعی دنبال می‌کنند.
          </p>
        </div>
      </div>
    </Style>
  );
}

export default AdEffectiveness;
