import React from "react";
import styled from "styled-components";
const Style = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  color: #ffff;
  margin-top: 220px;
  margin-bottom: 225px;
  .leftelement,
  .rightelement {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .rightelement {
    grid-column: 2/7;
  }
  .leftelement {
    grid-column: 8/13;
    @media screen and (max-width: 1250px) {
      grid-column: 8/14 !important;
    }
  }
  .titlesponsor {
    
    font-weight: 500;
    line-height: 54px;
    text-align: right;
    margin-bottom: 25px;
    font-size: var(--fs--32-24-22);
  }
  img {
    width: 100%;
    height: auto;
  }
  desc {
    font-size: var(--fs--24-20-18);
  }
  @media screen and (max-width: 1000px) {
    margin: 100px auto 125px !important ;
    width: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    .leftelement,
    .rightelement {
      width: 48%;
    }
    .titlesponsor {
      margin-bottom: 8px;
    }
  }
  @media screen and (max-width: 600px) {
    margin: 48px auto 48px !important ;
    width: calc(100% - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      margin-bottom: 16px !important;
    }
    .leftelement,
    .rightelement {
      width: 95%;
    }
    .titlesponsor {
      margin-bottom: 8px;
    }
  }
`;

export default function Sponsorcomponent(props) {
  //   if (window.innerWidth > 600) {
  return (
    <Style>
      <div className="rightelement">
        <img src={props.rightimg} alt={props.altsponser} />
        <p className="titlesponsor">{props.righttitle}</p>
        <desc>{props.rightdesc}</desc>
      </div>
      <div className="leftelement">
        <img src={props.leftimg} alt={props.altsponser} />
        <p className="titlesponsor">{props.lefttitle}</p>
        <desc>{props.leftdesc}</desc>
      </div>
    </Style>
  );
  //   } else {
  // return (
  //   <Style>
  //     <div className="topelement">{props.top}</div>
  //     <div className="bottomelement">{props.left}</div>
  //   </Style>
  // );
}
// }
