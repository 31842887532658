import React from "react";
import styled from "styled-components";
import QuestionComponent from "./QuestionComponent";
import { Link } from "react-router-dom";
import IconDown from "../../Assets/Icons/IconDown";
const Style = styled.div`
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  .headerquestions {
    grid-column: 2/14;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 64px;
    a {
      
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      font-size: 20px;
      align-items: center;
      text-align: right;
      text-decoration: none;
      color: var(--text-4);
      margin: 0 !important;
      svg {
        transform: rotate(90deg) !important;
        stroke: var(--text-4) !important;
        width: 1rem !important;
        margin-right: 0.3rem !important;
      }
    }
    p {
      
      font-size: 24px;
      font-weight: 500;
      line-height: 38px;
      color: var(--text-1);
      letter-spacing: 0em;
      text-align: right;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;

    .headerquestions {
      width: calc(100% - 30px);
      margin: 0 auto 30px;
      display: flex;
      justify-content: space-between;
      p {
        font-size: 20px;
      }
      a {
        font-size: 16px;
      }
    }
  }
`;

const questions = [
  {
    title: "اپلیکیشن پادکده بشنو چیست؟",
    desc: "پادکده بشنو به شما کمک می‌کند که پادکست‌های مورد علاقه خود را کشف کنید و از گوش دادن به آن‌ها لذت ببرید. ما مثل شما به پادکست‌ها علاقه‌مند هستیم و فکر می‌کنیم که پادکست‌ها راهی عالی برای یادگیری و سرگرم شدن هستند. به همین دلیل پادکده بشنو رو متناسب با سلیقه و نیازهای شنوندگان پادکست فارسی طراحی کردیم تا یک تجربه لذت‌بخش در شنیدن پادکست برای شما خلق کنیم. ",
  },
  {
    title: "پادکده بشنو چگونه کار می‌کند؟",
    desc: "پس از نصب اپلیکیشن و ثبت نام در آن از شما خواسته می‌شود که موضوعات مورد علاقه خود را انتخاب کنید تا ما بتوانیم پادکست‌هایی متناسب با سلیقه‌تان به شما پیشنهاد کنیم. پس از آن وارد دنیایی از پادکست‌های فارسی می‌شوید و به راحتی می‌توانید مجموعه مورد علاقه خود را جستجو، دنبال و دانلود کنید. می‌توانید با رفتن به صفحه تنظیمات و تکمیل مشخصات، به  ما کمک کنید تا صفحه اول شما را بهتر شخصی‌سازی کنیم. ",
  },
  {
    title: "آیا پادکده بشنو کاملا رایگان است؟",
    desc: "نصب پادکده بشنو و تمام ویژگی‌هایی که در حال حاضر روی آن قرار دارد و تجربه شنیدن پادکست فارسی را لذت‌بخش‌تر می‌کند کاملا رایگان است و رایگان خواهد ماند. همچنین ویژگی‌های بیشتری برای بهبود تجربه شنیدن پادکست فارسی در آینده اضافه خواهد شد که آن‌ها هم رایگان هستند.",
  },
  {
    title: "صفحه اصلی چگونه برای من شخصی‌سازی می‌شود؟",
    desc: "هنگام اولین ورود به اپلیکیشن از شما می‌خواهیم که موضوعات مورد علاقه خود را انتخاب کنید (که بعدا از طریق تب تنظیمات می‌توانید این موضوعات را تغییر دهید) و صفحه نخست را در ابتدا بر اساس موضوعات انتخابی شما می‌چینیم. در ادامه با توجه به رفتار شما در اپلیکیشن مثل اپیزودهایی که گوش داده‌اید، جستجوها و … چیدمان صفحه اصلی را تغییر می‌دهیم، تا شما برای پیدا کردن پادکست‌های محبوب خود نیازی به جستجو کردن نداشته باشید.",
  },
  {
    title: "چگونه می‌توانم بدون اینترنت در پادکده بشنو، پادکست گوش کنم؟",
    desc: "ما این امکان برای شما قرار داده‌ایم که اپیزودهای مورد علاقه خود را دانلود کنید و هرزمان در هرجایی که بودید حتی بدون دسترسی به اینترنت بتوانید به آن‌ها گوش دهید. ",
  },
  {
    title: "چگونه می‌توانم اپلیکیشن پادکده را دانلود کنم؟",
    desc: "اپلیکیشن پادکده بشنو، برای اندروید و ios در دسترس است و می‌توانید آن را به طور رایگان از مارکت‌های اپلیکیشن ایرانی دانلود و نصب کنید. همچنین نسخه وب نیز برای کاربران دسکتاپ در دسترس است. ",
  },
];
class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: -1,
    };
  }
  //for open one tab
  changeOpened = (value) => {
    this.setState({
      opened: value,
    });
  };
  makeQuestion = () => {
    return questions.map((value, index) => {
      return (
        <QuestionComponent
          key={index}
          title={value.title}
          desc={value.desc}
          ind={index}
          opened={this.state.opened}
          changeOpened={this.changeOpened}
        />
      );
    });
  };
  render() {
    return (
      //text desc arraye
      <Style className={this.props.className}>
        <div className="headerquestions">
          <p> سوالات متداول </p>
          {(!this.props.showmore) ? (
            <Link to="/contactus">
              مشاهده بیشتر
              <IconDown />
            </Link>
          ) : (
            ''
          )}
        </div>
        {this.makeQuestion()}
      </Style>
    );
  }
}
export default Questions;
