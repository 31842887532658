import React from "react";
export const IconUserCommentBlog = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2453 25.56C24.1053 25.54 23.9253 25.54 23.7653 25.56C20.2453 25.44 17.4453 22.56 17.4453 19.02C17.4453 15.4 20.3653 12.46 24.0053 12.46C27.6253 12.46 30.5653 15.4 30.5653 19.02C30.5453 22.56 27.7653 25.44 24.2453 25.56Z"
        stroke="#E5E7EB"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.4834 38.7601C33.9234 42.0201 29.2034 44.0001 24.0034 44.0001C18.8034 44.0001 14.0834 42.0201 10.5234 38.7601C10.7234 36.8801 11.9234 35.0401 14.0634 33.6001C19.5434 29.9601 28.5034 29.9601 33.9434 33.6001C36.0834 35.0401 37.2834 36.8801 37.4834 38.7601Z"
        stroke="#E5E7EB"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0039 44C35.0496 44 44.0039 35.0457 44.0039 24C44.0039 12.9543 35.0496 4 24.0039 4C12.9582 4 4.00391 12.9543 4.00391 24C4.00391 35.0457 12.9582 44 24.0039 44Z"
        stroke="#E5E7EB"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
