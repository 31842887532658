import React, { useRef } from "react";
import styled from "styled-components";
import image1 from "../../Assets/new1/topaboutus.svg"
import arrow from "../../Assets/new1/arrow-right.svg"
import { BookSeam } from "../Lines/BookSeam";
import { TextBtn } from "../Inputs/TextBtn";
import shadow from "../../Assets/new1/aboutus-shadow.svg"
import linesTop from "../../Assets/new1/toplines.svg"
import { BookSeamHorizontal } from "../Lines/BookSeamHorizontal";
const Style = styled.div`
    margin-top: 7rem;
    margin-bottom: 7rem;
    grid-column: 2/14;
    /* min-height: 100vh; */
    display: flex;
    position: relative;
    /* overflow: hidden; */
    &.left {
        flex-direction: row-reverse;
    }
    gap: 1rem;
    .circle-back {
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 100px;
        background-color: blue;
        bottom: -50px;
        left: -50px;
        box-shadow: var(--n-shadow-1);
        border: 3px solid var(--n-text-6);
        animation: float-3 7s ease-in-out infinite;
        animation-delay: -3s;
        &.left {
            flex-direction: row-reverse;
            left: auto;
            right: -50px;
        }
    }
    .outer-box {
        width: calc(50% - 0.5rem);
        border: 3px solid var(--n-text-6);
        border-radius: 4px;
        box-shadow: var(--n-shadow-1);
        background: var(--n-grey-05);
        position: relative;
        .lines-top {
            position: absolute;
            width: 250px;
            left: calc(50% - 125px);
            top: -36px;
        }
        .content {
            margin: 31px 3rem -1rem;
            padding: 3rem 2rem 2rem;
            border: 3px solid var(--n-text-6);
            border-radius: 4px;
            box-shadow: var(--n-shadow-1);
            background: var(--n-grey-05);
            text-align: center;
            .title {
                font-size: 32px;
                font-weight: 700;
            }
            .desc {
                font-size: 14px;
                font-weight: 500;
                color: var(--n-grey-7);
                margin: 1rem 0;
            }
            .btn {
                padding: 3rem 20% 1rem;
            }
        }
    }
    .center-seam {
      position: absolute;
      z-index: 100;
      top: 0;
      left: calc(50% - 30px);
      bottom: 0;
    }
    .center-seam-h {
      display: none;
    }
    .image {
        width: calc(50% - 0.5rem);
        border: 3px solid var(--n-text-6);
        border-radius: 4px;
        box-shadow: var(--n-shadow-1);
        background: var(--n-grey-05);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .main-img {
            width: 60%;
            margin: auto;
            animation: float-3 7s ease-in-out infinite;
            animation-delay: -1s;
        }
        .shadow-img {
            position: absolute;
            width: 60%;
            top: 75%;
            animation: shadow-3 7s ease-in-out infinite;
            animation-delay: -1s;
        }
    }
    @keyframes float-3 {
        0% {
            transform: translatey(0px);
        }
        50% {
            transform: translatey(-30px);
        }
        100% {
            transform: translatey(0px);
        }
    }
    @keyframes shadow-3 {
        0% {
            transform: scaleX(1);
        }
        50% {
            transform: scaleX(0.9);
        }
        100% {
            transform: scaleX(1);
        }
    }
    @media screen and (max-width: 1000px) {
        margin-top: 3rem;
        margin-bottom: 3rem;
        flex-direction: column;
        gap: 0.5rem;
        &.left {
            flex-direction: column;
        }
        .outer-box {
            width: calc(100% - 0.4rem);
            .lines-top {
                width: 180px;
                left: calc(50% - 90px);
                top: -29px;
            }
            .content {
                margin: 19px 1rem 3rem;
                padding: 2rem 1rem 1rem;
                .btn {
                    padding: 1rem 1rem 1rem;
                }
            }
        }
        .image {
            width: calc(100% - 0.4rem);
            aspect-ratio: 1.2;
            .main-img {
                width: 75%;
            }
            .shadow-img {
                position: absolute;
                width: 75%;
            }
        }
        .center-seam {
            display: none;
        }
        .center-two {
            z-index: 101;
            position: relative;
        }
        .center-seam-h {
            display: flex;
            position: absolute;
            z-index: 100;
            top: calc(-20px);
            left: 0;
            bottom: 0;
        }
        .circle-back {
            display: none;
        }
    }
`
export default function AboutUsBoxes({data, index}) {
    return (
        <Style className={data.dir} key={index}>
            <div className={"circle-back " + data.dir} style={{backgroundColor: data.color2}}></div>
            <div className="outer-box" style={{backgroundColor: data.color}}>
                <img className="lines-top" src={linesTop} alt="lines"/>
                <div className="content">
                    <h3 className="title">{data.title}</h3>
                    <div className="desc">{data.desc}</div>
                    <div className="btn">
                        <TextBtn
                            type={"link"}
                            text={data.btnText}
                            // onclick={sendData}
                            loading={false}
                            disabled={false}
                            color={data.btnColor}
                            href={data.href}
                        />
                    </div>
                </div>
            </div>
            <div className="center-two">
            <BookSeamHorizontal className={"center-seam-h"}/>
            </div>
            <div className="image" style={{backgroundColor: data.color}}>
                <img className={"shadow-img"} src={shadow} alt="shadow"/>
                <img className={"main-img"} src={data.image} alt={data.title}/>
            </div>
            <BookSeam className={"center-seam"}/>
        </Style>
    )
}