import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const Style = styled.div`
  width: 100%;
  margin-bottom: 80px;
  p {
    
    font-style: normal;
    font-weight: 500;
    font-size: var(--fs--32-24-22);
    line-height: 28px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #ffffff;
  }
  img {
    margin-bottom: 40px;
    width: 100%;
  }
  desc {
    
    font-style: normal;
    font-weight: 400;
    font-size: var(--fs--24-20-18);
    line-height: 41px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #e5e7eb;
    margin-bottom: 80px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    p {
      margin-bottom: 24px;
    }
    img {
      margin-bottom: 16px;
    }
    desc {
      margin-bottom: 56px;
    }
  }
`;
export default function BlogTitrComponentt({
  className,
  style,
  img,
  text,
  title,
}) {
  return (
    <Style style={style} className={className}>
      <p> {title} </p>
      <Link to="/">
        <img alt="title" src={img} />
      </Link>
      <desc> {text}</desc>
    </Style>
  );
}
