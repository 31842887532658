import React from "react";
import styled from "styled-components";
import { NavPodkadehSochial } from "../Components/sochial/NavPodkadehSochial";
import MainLayout from "./Layout/MainLayout";
import ImgHeadreAboutUs from "../Assets/Images/ImgHeadreAboutUs.jpg";
import TopImage from "../Components/Helper/TopImage";

import beshno from "../Assets/Images/Aboutus/beshno-aboutus.png"
import besaz from "../Assets/Images/Aboutus/besaz-aboutus.png"
import besaznew from "../Assets/new1/besaz-logo.png"
import shenideshonew from "../Assets/new1/shenidesho-logo.png"
import shenidesho from "../Assets/Images/Aboutus/shenidesho-aboutus.png"
import AboutUsTopPage from "../Components/Aboutus/AboutUsTopPage";
import AboutUsBoxes from "../Components/Aboutus/AboutUsBoxes";
import SmallContact from "../Components/Aboutus/SmallContact";
import { Language } from "../Language/Language";
import { lang } from "../App";

const Style = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  margin-top: 60px;
  margin-bottom: 70px;
  align-items: center;
  justify-content: center;
  position: relative;
  --fs-about-us-title: 32px;
  --fs-about-us-desc: 20px;
  @media screen and (max-width: 1750px) {
    --fs-about-us-title: 28px;
    --fs-about-us-desc: 17px;
  }
  @media screen and (max-width: 1500px) {
    --fs-about-us-title: 24px;
    --fs-about-us-desc: 15px;
  }
  @media screen and (max-width: 1250px) {
    --fs-about-us-title: 20px;
    --fs-about-us-desc: 14px;
  }
  @media screen and (max-width: 1000px) {
    --fs-about-us-title: 32px;
    --fs-about-us-desc: 20px;
  }
  @media screen and (max-width: 600px) {
    --fs-about-us-title: 20px;
    --fs-about-us-desc: 16px;
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: 4.09% repeat(12, calc(calc(88.7% / 12) - calc(17.16% / 12))) 4.09%;
  }
  /* .container-aboutus {
    width: 100%;
    grid-column: 2/14;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 0.78vw));
    grid-gap: 1.56vw;
    padding: 2rem 2.5rem;
    background: var(--primary-1);
    box-shadow: var(--shadow-2);
    border-radius: 16px;
    margin: 0.5rem 0;
    box-sizing: border-box;
    .image {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-size: var(--fs-about-us-title);
        font-weight: 500;
        margin-bottom: calc( var(--fs-about-us-title) * 0.5);
      }
      .desc {
        font-size: var(--fs-about-us-desc);
        margin-top: calc( var(--fs-about-us-desc) * 0.5);
        text-align: justify;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    margin-top: 55px;
    grid-template-columns:
      14px repeat(6, calc(calc(calc(100% - 60px) / 6) - calc(80px / 6)))
      14px;
    grid-column-gap: 16px;
    .container-aboutus {
      grid-column: 1/9;
      border-radius: 0;
      padding: 2rem 1.5rem;
      .image {
        grid-column: span 2;
      }
      .content {
        grid-column: span 2;
      };
    }
   
  }
  @media screen and (max-width: 600px) {
    margin-top: 50px;
    .container-aboutus {
      padding: 2rem 1rem;
    }
  } */
`;

export default function AboutUs(props) {
  const data = [
    // {
    //   title: 'بشنو',
    //   desc: 'بشنو با هدف ایجاد یک تجربه لذت‌بخش و به‌ یاد ماندنی در شنیدن پادکست، طراحی شده است. در این سرویس تلاش شده تا به خوبی به تمام نیازهای شنوندگان پادکست فارسی پاسخ داده شود. ',
    //   image: beshno,
    //   dir: 'right',
    //   color: "#C7DFBE",
    // },
    {
      title: Language[lang].a2Title,
      desc: Language[lang].a2Desc,
      image: besaznew,
      dir: 'left',
      color: "#C7DFBE",
      color2: "#ADD0D8",
      btnColor: 1,
      btnText: Language[lang].loginpod,
      href:"https://podkadeh.com/podcaster"
    },
    {
      title: Language[lang].a3Title,
      desc: Language[lang].a3Desc,
      image: shenideshonew,
      dir: 'right',
      color: "#ADD0D8",
      color2: "#F9CEB3",
      btnColor: 2,
      btnText:  Language[lang].loginpod,
      href:"https://podkadeh.com/podcaster"
    }
  ]
  const makeBoxes = () => {
    return data.map((value , index) => {
      return (
        <AboutUsBoxes data={value} key={index} index={index}/>
      )
      // if(value.dir === 'right' || window.innerWidth <= 1000){
        
      // } else {
      //   return (
      //     <div className="container-aboutus" key={index}>
      //       <div className="content">
      //         <h3>{value.title}</h3>
      //         <div className="desc">{value.desc}</div>
      //       </div>
      //       <div className="image"><img src={value.image} alt={value.title}/></div>
      //     </div>
      //   )
      // }
    })
  }

  return (
    <MainLayout {...props}>
      <Style>
        <AboutUsTopPage/>
       {makeBoxes()}
         <SmallContact/>
        {/* <TopImage image={ImgHeadreAboutUs}/>
        <div className="container-aboutus">
          <div className="content" style={{gridColumn: 'span 2'}}>
            <h3> درباره پادکده </h3>
            <div className="desc">
              پادکده یک پلتفرم جامع پادکست فارسی است. پادکست عضو جدید خانواده
              رسانه است که در دنیا با استقبال زیادی مواجه شده است. پادکست‌ها
              سوار بر موج تکنولوژی در دنیا به سرعت در حال پیشرفت هستند. ما در
              پادکده تلاش می‌کنیم تا صنعت پادکست در ایران، همگام با این موج
              پیشروی کند. برای دستیابی به این هدف مهم ما سه سرویس بشنو، بساز و
              شنیده شو را طراحی کرده‌ایم.
            </div>
          </div>
        </div>
        {makeBoxes()}
        <div className="container-aboutus">
          <div className="content" style={{gridColumn: 'span 2'}}>
            <div className="desc">
              تیم پادکده با استفاده از تکنولوژی‌های روز دنیا و آشنایی کامل با صنعت پادکست، تلاش می‌کند تا تمامی مخاطبان و کاربران این سرگرمی جذاب، تجربه لذت‌بخشی در استفاده از آن داشته باشند.
            </div>
            <NavPodkadehSochial />
          </div>
        </div> */}
      </Style>
    </MainLayout>
  );
}

