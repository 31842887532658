import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IconError } from "../../Assets/Icons/IconError";
import { IconEye } from "../../Assets/Icons/IconEye";
import { IconEyeslash } from "../../Assets/Icons/IconEyeslash";
import { Tooltip } from "./Tooltip";
import { Language } from "../../Language/Language";
import { lang } from "../../App";

const Styles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &.inputareawtitle-ltr {
    .label-textinput{
      right: auto;
      left: calc(var(--fs-textfield) * 1.5);
    }
    .error {
      .text {
        margin: 0.1rem 0 0 0.2rem;
      }
    }
  }
  .text-field {
    z-index: 3;
    width: calc(100% - 2rem);
    padding: calc(var(--fs-textfield) * 2) calc(1rem - 2px) var(--fs-textfield);
    border-radius: var(--border-radius-2);
    color: var(--n-text-6);
    border: 2px var(--n-text-6) solid;
    outline: none;
    transition: var(--trans-1);
    font-size: var(--fs-textfield);
    background: transparent;
    &.error-text {
      border: 1px var(--secondry-1) solid;
    }
    &.ltr-text {
      text-align: left;
      direction: ltr;
    }
    &.center-text {
      text-align: center;
      direction: ltr;
    }
    :focus {
      border: 2px var(--n-primary-10) solid;
      background-color: var(--n-grey-05);
      box-shadow: var(--n-shadow-1-hover);
    }
  }
  .eye {
    cursor: pointer;
    position: absolute;
    z-index: 5;
    top: calc(var(--fs-textfield) * 1.5);
    left: calc(var(--fs-textfield) * 1.5);
  }
  .label-textinput{
    z-index: 2;
    position: absolute;
    color: var(--n-text-6);
    top: calc(var(--fs-textfield) * 1.5);
    right: calc(var(--fs-textfield) * 1.5);
    transition: var(--trans-1);
    &.active{
      color: var(--n-grey-7);
      z-index: 5;
      top: calc(var(--fs-textfield) * 0.5);
      font-size: calc(var(--fs-textfield) * 0.8);
    }
  }
  
  .help-input{
    z-index: 5;
    position: absolute;
    top: calc(var(--fs-textfield) * 1.4);
    left: calc(var(--fs-textfield) * 1.5);
    transition: var(--trans-1);
    .icon {
      width: 24px;
      transition: var(--trans-1);
    }
    &.password {
      left: calc(var(--fs-textfield) * 3.5);
    }
    .helpText{
      z-index: 5;
    }
  }
  .help-pass {
    padding: 0.3rem 0.3rem 0;
    font-size: var(--fs-small);
  }
  .error {
    display: flex;
    height: 0.75rem;
    font-size: var(--fs-small);
    .icon {
      width: 1rem;
      height: 1rem;
    }
    .text {
      margin: 0.1rem 0.2rem 0 0;
      color: red;
    }
  }
  @media screen and (max-width: 1445px) {
    &.inputareawtitle-ltr {
      .label-textinput{
        right: auto;
        left: calc(var(--fs-textfield) * 1.05);
      }
    }
    .text-field {
      padding: calc(var(--fs-textfield) * 1.4) calc(1rem - 1px) calc(var(--fs-textfield) * 0.7);
    }
    .eye {
      top: calc(var(--fs-textfield) * 1.05);
      left: calc(var(--fs-textfield) * 1.05);
    }
    .label-textinput{
      top: calc(var(--fs-textfield) * 1.05);
      right: calc(var(--fs-textfield) * 1.05);
      &.active{
        top: calc(var(--fs-textfield) * 0.3);
        font-size: calc(var(--fs-textfield) * 0.8);
      }
    }
    .help-input{
      top: calc(var(--fs-textfield) * 1);
      left: calc(var(--fs-textfield) * 1.05);
    }
  }
`;

// dokme chesm baray ramz ha
export const TextAreaWTitle = ({
  style,
  name,
  type = "text",
  error,
  value,
  onchange,
  title,
  required,
  ltr,
  center,
  onEnter,
  lines,
  help,
}) => {
  const [showPass, setShowPass] = useState(false);
  const [focused , setFocused] = useState(false);
  useEffect(() => {
    if(value === '' && focused === false){
      setFocused(false);
    } else {
      setFocused(true);
    }
  }, [value, focused]);



  let errorComp = "";
  let classes = "text-field";
  if (error.toString()) {
    classes = "text-field error-text";
    errorComp = (
      <React.Fragment>
        <IconError />
        <div className="text">{error}</div>
      </React.Fragment>
    );
  }

  if (ltr) {
    classes += " ltr-text";
  }

  if (center) {
    classes += " center-text";
  }
  //
  let iconEye = "";
  if (type === "password") {
    if (showPass) {
      iconEye = (
        <div className="eye" onClick={() => setShowPass(false)}>
          <IconEyeslash />
        </div>
      );
    } else {
      iconEye = (
        <div className="eye" onClick={() => setShowPass(true)}>
          <IconEye />
        </div>
      );
    }
  }
  function handleKeyPress(e) {
    if (e.key === "Enter") {
      if (typeof onEnter === "function") {
        onEnter();
      }
    }
  }
  let helpElement = ''
  if(help){
    helpElement = (
      <Tooltip ClassTooltip={"help-input"} ClassText={'helpText'} width="10rem" right={'-5rem'}>
        {help}
      </Tooltip>
    );
  }
  return (
    <Styles style={style} className={" inputareawtitle-" +Language[lang].dir }>
      <textarea
        onKeyPress={handleKeyPress}
        className={classes}
        name={name}
        onChange={onchange}
        required={required}
        rows={lines}
        value={value}
        onFocus={(e) => setFocused(true)}
        onBlur={(e) => ((value === '') ? setFocused(false) : '')}
      >
        {value}
      </textarea>
      <label className={"label-textinput " + ((focused) ? 'active' : '')}>
        {title}
      </label>
      {helpElement}
      {iconEye}
      <div className="error">{errorComp}</div>
    </Styles>
  );
};
