import React from "react";
import styled from "styled-components";
import Welcomesponsor from "../Assets/Images/welcome sponser.png";
import Sponsorcomponent from "../Components/Sponsor/SponsorComponent";
import MainLayout from "./Layout/MainLayout";
import Sponsor1 from "../Assets/Images/sponsor1.png";
import Sponsor2 from "../Assets/Images/sponsor2.png";
import Sponsor3 from "../Assets/Images/sponsor3.png";
import AdEffectiveness from "../Components/Sponsor/AdEffectiveness";
import ContainerWebComponent from "../Components/Container/ContainerWebComponent";
const Style = styled.div`
  height: 100%;
  z-index: 100;
  background: #252526;
  color: #ffffff;
  .container {
    .linktheme {
      color: var(--text-1) !important;
      background: var(--text-12) !important;
    }
  }
  .header-form {
    display: grid;
    grid-template-columns:
      6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
      6.09%;
    grid-column-gap: 1.56%;
    @media screen and (max-width: 1000px) {
      display: flex;
      width: calc(100% - 60px);
      flex-direction: column;
      margin: 0 auto 72px;
    }
    .header-form-title {
      margin-bottom: 36px;
      
      font-weight: 500;
      font-size: var(--fs--48-30-24);
      line-height: 81px;
      letter-spacing: 0px;
      text-align: center;
      grid-column: 6/9;
    }
    .header-form-desc {
      grid-column: 2/14;
      
      font-size: var(--fs--24-20-18);
      font-weight: 400;
      letter-spacing: 0px;
      text-align: right;
    }
  }
  @media screen and (max-width: 1250px) {
    .title {
      line-height: 50px !important;
      margin: 0 !important;
    }
    .desc {
      line-height: 33px !important;
      margin-bottom: 24px !important;
    }
  }
  @media screen and (max-width: 1000px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
    z-index: 100;
    background: #252526;
    color: #ffffff;
    .title {
      
      font-weight: 500 !important;
      line-height: 47px !important;
      letter-spacing: 0em;
      text-align: right;
    }
    .text {
      
      font-weight: 400 !important;
      line-height: 34px !important;
      letter-spacing: 0em;
      text-align: right;
    }

    .propertise {
      display: flex;
      flex-direction: column;
    }
    .header-form {
      margin: 64px auto 40px !important;
      .header-form-title {
        margin-bottom: 8px !important;
        font-size: var(--fs--48-30-24);
        font-weight: 500 !important;
        line-height: 54px !important;
        letter-spacing: 0px;
        text-align: center;
      }
      .header-form-desc {
        font-size: var(--fs--24-20-18);
        
        font-weight: 400 !important;
        line-height: 34px !important;
        letter-spacing: 0px;
        text-align: right;
      }
    }
  }
`;
const Form = styled.form`
  margin-top: 60px;
  margin-bottom: 157px !important;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  .filds {
    grid-column: 3/13;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .filds-footer {
    grid-column: 1/13;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    button {
      background: #2e4ffd;

      border-radius: 15px;
      border: none !important;
      width: 25%;
      height: 58px;
      color: #ffffff;
      font-size: var(--fs--24-20-20);
    }
    desc {
      display: flex;
      font-size: var(--fs--24-16-0) !important;
      p {
        color: #fd502e;
        font-size: var(--fs--24-16-0) !important;
      }
    }
  }
  .fild-input {
    width: 47%;
    p {
      display: inline;
      font-size: var(--fs--24-20-18);
    }
    input {
      margin-top: 4px;
      background: none;
      border: 1px solid #c8c6c4;
      border-radius: 15px;
      height: 70px;
      width: 100%;
    }
  }
  @media screen and (max-width: 1000px) {
    display: flex !important;
    width: calc(100% - 60px) !important;
    flex-direction: column;
    margin: 72px auto 80px !important;
    .filds {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 32px !important ;
    }
    .filds-footer {
      grid-column: 3/13;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      button {
        background: #2e4ffd;
        border-radius: 15px;
        width: 50%;
        height: 58px;
        color: #ffffff;
        font-size: var(--fs--24-20-20);
      }
    }
    .fild-input {
      width: 48%;
      p {
        display: inline;
        font-size: var(--fs--24-20-18);
      }
      input {
        margin-top: 3px;
        background: none;
        border: 1px solid #c8c6c4;
        border-radius: 15px;
        height: 70px;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    display: flex !important;
    width: calc(100% - 60px) !important;
    flex-direction: column;
    margin: 40px auto 72px !important;
    .filds {
      display: flex;
      flex-direction: column !important;
      justify-content: space-between;
    }
    .filds-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      button {
        background: #2e4ffd;
        border-radius: 15px;
        width: 100% !important ;
        height: 58px;
        color: #ffffff;
        font-size: var(--fs--24-20-20);
      }
    }
    .fild-input {
      margin-bottom: 24px !important ;
      width: 100% !important;
      p {
        display: inline;
        font-size: var(--fs--24-20-18);
      }
      input {
        margin-top: 3px;
        background: none;
        border: 1px solid #c8c6c4;
        border-radius: 15px;
        height: 70px;
        width: 100%;
      }
    }
  }
`;

export default function Sponsor(props) {
  if (window.innerWidth > 600) {
    return (
      <MainLayout {...props}>
        <Style>
          <ContainerWebComponent
            {...props}
            className="container"
            title="شنیده شو"
            text="آینده روشن تبلیغات در پادکست"
            desc="ما تبلیغات در پادکست را متحول کردیم"
            img={Welcomesponsor}
            linkone="درخواست مشاوره"
            linktwo="چرا پادکده؟"
          />

          <Sponsorcomponent
            rightimg={Sponsor1}
            lefttitle={"تبلیغات بدون محدودیت"}
            leftdesc={
              "سرویس شنیده شو از طریق درج تبلیغات پویا در پادکست، نه تنها صمیمیت و کیفیت تبلیغات سنتی را حفظ می‌کند بلکه تمام محدودیت‌های آن را نیز حذف می‌کند. در شنیده شو، آمار و ارقام دقیقی از عملکرد تبلیغات خود دریافت می‌کنید. پیام تبلیغاتی شما به صورت ثابت روی یک اپیزود قرار نمی‌گیرد و می‌توانید در هر کمپین پیام متفاوتی را به گوش شنوندگان برسانید."
            }
          />
          <Sponsorcomponent
            leftimg={Sponsor2}
            righttitle={"تبلیغات هدفمند"}
            rightdesc={
              "شنیده شو به تبلیغ کنندگان این فرصت را می‌دهد که شنوندگان را براساس معیارهایی مثل محل زندگی، زمان و تاریخ گوش دادن و حتی نوع دستگاه و سیستم عاملی که از طریق آن پادکست گوش می‌دهند، انتخاب کنند. همچنین در این سرویس تبلیغ کنندگان می‌توانند یک یا چند دسته‌بندی موضوعی خاص را انتخاب کنند تا بتوانند تبلیغات خود را روی علایق مخاطب متمرکز کنند."
            }
          />
          <Sponsorcomponent
            rightimg={Sponsor3}
            lefttitle={"تبلیغات مقرون به صرفه "}
            leftdesc={
              "در شنیده شو، تبلیغات در پادکست را بدون هیچ محدودیت قیمتی و با هرمیزان بودجه‌ای می‌توانید شروع کنید. شیوه محاسبه نرخ تبلیغات در شنیده شو کاملا شفاف و روشن است و هزینه ها بر اساس تعداد شنیده شدن تبلیغات شما محاسبه می‌شود."
            }
          />
          <AdEffectiveness />

          <div className="header-form">
            <p className="header-form-title"> درخواست مشاوره </p>
            <p className="header-form-desc">
              از طریق فرم زیر در لیست انتظار “شنیده شو” ثبت نام کنید و جزو اولین
              برندهای ایرانی باشید که از طریق تبلیغات پویا پیام خود را به گوش
              شنوندگان پادکست می‌رسانند. همچنین با تکمیل فرم زیر می‌توانید از
              مشاوران تبلیغاتی ما در ساخت اولین کمپین‌ تبلیغاتی خود به صورت
              رایگان کمک بگیرید.
            </p>
          </div>
          <Form>
            <div className="filds">
              <div className="fild-input">
                <p>نام و نام خانوادگی *</p>
                <input />
              </div>
              <div className="fild-input">
                <p>سمت*</p>
                <input />
              </div>
            </div>
            <div className="filds">
              <div className="fild-input">
                <p>شماره تلفن همراه *</p>
                <input />
              </div>
              <div className="fild-input">
                <p> نام شرکت یا محصول *</p>
                <input />
              </div>
            </div>
            <div className="filds">
              <div className="fild-input">
                <p>آدرس سایت</p>
                <input />
              </div>
              <div className="fild-input">
                <p> نام شرکت یا محصول *</p>
                <input />
              </div>
            </div>
            <div className="filds-footer">
              <button>ثبت درخواست</button>
            </div>
          </Form>
        </Style>
      </MainLayout>
    );
  } else {
    return (
      <MainLayout {...props}>
        <Style>
          <ContainerWebComponent
            {...props}
            className="container"
            title="شنیده شو"
            text="آینده روشن تبلیغات در پادکست"
            desc="ما تبلیغات در پادکست را متحول کردیم"
            img={Welcomesponsor}
            linkone="درخواست مشاوره"
            linktwo="چرا پادکده؟"
          />
          <Sponsorcomponent
            rightimg={Sponsor1}
            lefttitle={"تبلیغات بدون محدودیت"}
            leftdesc={
              "سرویس شنیده شو از طریق درج تبلیغات پویا در پادکست، نه تنها صمیمیت و کیفیت تبلیغات سنتی را حفظ می‌کند بلکه تمام محدودیت‌های آن را نیز حذف می‌کند. در شنیده شو، آمار و ارقام دقیقی از عملکرد تبلیغات خود دریافت می‌کنید. پیام تبلیغاتی شما به صورت ثابت روی یک اپیزود قرار نمی‌گیرد و می‌توانید در هر کمپین پیام متفاوتی را به گوش شنوندگان برسانید."
            }
          />
          <Sponsorcomponent
            rightimg={Sponsor2}
            lefttitle={"تبلیغات هدفمند"}
            leftdesc={
              "شنیده شو به تبلیغ کنندگان این فرصت را می‌دهد که شنوندگان را براساس معیارهایی مثل محل زندگی، زمان و تاریخ گوش دادن و حتی نوع دستگاه و سیستم عاملی که از طریق آن پادکست گوش می‌دهند، انتخاب کنند. همچنین در این سرویس تبلیغ کنندگان می‌توانند یک یا چند دسته‌بندی موضوعی خاص را انتخاب کنند تا بتوانند تبلیغات خود را روی علایق مخاطب متمرکز کنند."
            }
          />
          <Sponsorcomponent
            rightimg={Sponsor3}
            lefttitle={"تبلیغات مقرون به صرفه "}
            leftdesc={
              "در شنیده شو، تبلیغات در پادکست را بدون هیچ محدودیت قیمتی و با هرمیزان بودجه‌ای می‌توانید شروع کنید. شیوه محاسبه نرخ تبلیغات در شنیده شو کاملا شفاف و روشن است و هزینه ها بر اساس تعداد شنیده شدن تبلیغات شما محاسبه می‌شود."
            }
          />
          <div className="header-form">
            <p className="header-form-title"> درخواست مشاوره </p>
            <p className="header-form-desc">
              از طریق فرم زیر در لیست انتظار “شنیده شو” ثبت نام کنید و جزو اولین
              برندهای ایرانی باشید که از طریق تبلیغات پویا پیام خود را به گوش
              شنوندگان پادکست می‌رسانند. همچنین با تکمیل فرم زیر می‌توانید از
              مشاوران تبلیغاتی ما در ساخت اولین کمپین‌ تبلیغاتی خود به صورت
              رایگان کمک بگیرید.
            </p>
          </div>
          <Form>
            <div className="filds">
              <div className="fild-input">
                <p>نام و نام خانوادگی *</p>
                <input />
              </div>
              <div className="fild-input">
                <p>سمت*</p>
                <input />
              </div>
            </div>
            <div className="filds">
              <div className="fild-input">
                <p>شماره تلفن همراه *</p>
                <input />
              </div>
              <div className="fild-input">
                <p> نام شرکت یا محصول *</p>
                <input />
              </div>
            </div>
            <div className="filds">
              <div className="fild-input">
                <p>آدرس سایت</p>
                <input />
              </div>
              <div className="fild-input">
                <p> نام شرکت یا محصول *</p>
                <input />
              </div>
            </div>
            <div className="filds-footer">
              <button>ثبت درخواست</button>
            </div>
          </Form>
        </Style>
      </MainLayout>
    );
  }
}
