import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Style = styled.div`
  height: 100%;
  color: var(--text-1);
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  margin-top: 60px;
  margin-bottom: 100px;
  align-items: center;
  justify-content: center;
  .content {
    grid-column: 2/8;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: var(--fs--64-30-24) !important;
    margin-bottom: 0.5rem;
    color: var(--text-1) !important;
    font-family: KalamehWebFaNum !important;
    font-weight: 500 !important;
    line-height: 109px;
    letter-spacing: 0em !important;
    text-align: right !important;
  }
  .text {
    color: var(--text-1);
    
    font-weight: 500;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: right;

    font-size: var(--fs--36-24-20) !important;
  }
  .desc {
    font-size: var(--fs--28-20-18) !important;
    
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: right;

    margin-bottom: 36px;
  }
  .imagewelcome {
    grid-column: 8/14;
    img {
      width: 100% !important;
      height: auto !important;
      margin-top: 24px !important;
      @media (max-width: 1000px) {
        margin-top: 12px !important;
      }
      @media (max-width: 600px) {
        margin-top: 6px !important;
        margin-bottom: 20px;
      }
    }
  }
  .links {
    display: flex;
    flex-direction: row;
  }
  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .linktheme {
    font-size: var(--fs--22-16-20) !important;
    font-weight: 500;
    width: 246px;
    height: 60px;
    box-sizing: border-box !important;
    left: 1527px;
    top: 562px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    margin-left: 30px;
    &.disable {
      opacity: 0.5;
    }
  }
  .linkwhypod {
    font-size: var(--fs--22-16-20) !important;
    width: 246px;
    height: 60px;
    font-weight: 500;
    left: 1527px;
    box-sizing: border-box !important;
    top: 562px;
    color: var(--text-1);
    background: none;
    border-radius: 15px;
    border: 1px solid #edebe9 !important;
    cursor: pointer;
    &.disable {
      opacity: 0.5;
    }
  }
  @media (max-width: 1000px) {
    margin-bottom: 80px;
    margin-top: 55px;
  }
  @media (max-width: 600px) {
    margin: 0 auto 0;
    display: flex;
    width: calc(100% - 60px) !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    .content {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 36px;
    }
    .title {
      
      font-weight: 500;
      line-height: 47px;
      letter-spacing: 0em;
      text-align: right;

      margin-bottom: 4px;
    }
    .desc {
      margin-bottom: 16px;
    }
    .links {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-weight: 500 !important;
      line-height: 34px !important;
    }
    .linktheme {
      font-weight: 500 !important;
      line-height: 34px !important;
      height: 58px !important;
      width: 100% !important;
      border-radius: 15px;
      margin-bottom: 20px;
    }
    .linkwhypod {
      height: 58px !important;
      width: 100% !important;
    }
  }
`;
export default function ContainerWebComponent(props) {
  if (window.innerWidth > 600) {
    return (
      <Style className={props.className} style={props.style}>
        <div className="content ">
          <p className="title">{props.title}</p>
          <p className="text"> {props.text}</p>
          <p className="desc"> {props.desc}</p>
          <div className="links">
            <Link to={(props.linkoneLoc) ? props.linkoneLoc : '#'} className={"link linktheme " + ((!props.linkoneLoc) ? 'disable' : '')}>
              {props.linkone}
            </Link>
            <Link to={(props.linktwoLoc) ? props.linktwoLoc : '#'} className={"link linkwhypod " + ((!props.linktwoLoc) ? 'disable' : '')}>
              {props.linktwo}
            </Link>
          </div>
        </div>
        <div className="imagewelcome">
          <img src={props.img} alt="devices" />
        </div>
      </Style>
    );
  } else {
    return (
      <Style className={props.className} style={props.style}>
        <div className="content ">
          <p className="title">{props.title}</p>
          <p className="text"> {props.text}</p>
          <p className="desc"> {props.desc}</p>
        </div>
        <div className="imagewelcome">
          <img src={props.img} alt="devices" />
        </div>
        <div className="links">
          <Link to={(props.linkoneLoc) ? props.linkoneLoc : '#'} className={"link linktheme " + ((!props.linkoneLoc) ? 'disable' : '')}>
            {props.linkone}
          </Link>
          <Link to={(props.linktwoLoc) ? props.linktwoLoc : '#'} className={"link linkwhypod " + ((!props.linktwoLoc) ? 'disable' : '')}>
            {props.linktwo}
          </Link>
        </div>
      </Style>
    );
  }
}
