import React from "react";
import styled from "styled-components";
import BlogComponents from "./BlogComponent";
import { Link } from "react-router-dom";
import { IconArrowLeft } from "../../Assets/Icons/IconArrowLeft";
const Style = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 169px;
  margin-top: 200px;
  .blogs-home {
    .desc {
      width: 100% !important;
    }
  }
  .header {
    display: grid;
    grid-template-columns:
      6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
      6.09%;
    grid-column-gap: 1.56%;
    a {
      text-decoration: none;
      color: #ffffff !important ;
      svg {
        margin-right: 9px;
      }
    }
    margin-bottom: 16px;
  }
  .title {
    grid-column: 2/6;
    font-size: var(--fs--40-28-20);
    display: flex;
    align-items: center;
    text-align: right;
    color: #ffffff;
  }
  .morecaption {
    font-size: var(--fs--36-20-18) !important;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    grid-column: 10/14;
  }
  .blogs {
    display: grid;
    grid-template-columns:
      6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
      6.09%;
    grid-column-gap: 1.56%;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    padding: 0 !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
    flex-direction: column !important;
    width: 85% !important;
    margin: 0 auto;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    .title {
      position: static;
      margin: 0 !important;
      line-height: 34px;
    }
    .blogs {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
`;
export default function Blogs({ className, style }) {
  return (
    <Style style={style} className={className}>
      <div className="header">
        <p className={"title"}> وبلاگ پادکده</p>
        <Link to="/" className={"morecaption"}>
          مطالب بیشتر <IconArrowLeft />
        </Link>
      </div>
      <div className="blogs">
        <BlogComponents
          className={"blogs-home"}
          style={{ gridColumn: "2/6" }}
          title={"عنوان متن"}
          text={
            "لورم ایپسوم متن ساختگی  با تولید سادگی نامفهوم از صنعت چاپ و با با تولید سادگی نامفهوم از صنعت چاپ و با می باشد"
          }
        />
        <BlogComponents
          className={"blogs-home"}
          style={{ gridColumn: "6/10" }}
          title={"عنوان متن"}
          text={
            "لورم ایپسوم متن ساختگی  با تولید سادگی نامفهوم از صنعت چاپ و با با تولید سادگی نامفهوم از صنعت چاپ و با می باشد"
          }
        />
        <BlogComponents
          className={"blogs-home"}
          style={{ gridColumn: "10/14" }}
          title={"عنوان متن"}
          text={
            "لورم ایپسوم متن ساختگی  با تولید سادگی نامفهوم از صنعت چاپ و با با تولید سادگی نامفهوم از صنعت چاپ و با می باشد"
          }
        />
      </div>
    </Style>
  );
}
