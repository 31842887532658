import React, { useRef } from "react";
import styled from "styled-components";
import { Language } from "../../Language/Language";
import { lang } from "../../App";
import FormContactUs from "../ContactUs/FormContactUs";
import phone1 from "../../Assets/new1/phone2.svg"
import { NavPodkadehSocial2 } from "../sochial/NavPodkadehSocial2";
import { LinesOne } from "../Lines/LinesOne";
import { BookSeam } from "../Lines/BookSeam";
import { BookSeamHorizontal } from "../Lines/BookSeamHorizontal";
import { TextBtn } from "../Inputs/TextBtn";

const Style = styled.div`
  background: none;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 1rem;
  grid-column: 1/15;
  display: flex;
  flex-direction: column;
  &.contactus-ltr {
    direction: ltr;
  }
  .container-contactus {
    margin-bottom: 136px;
    display: grid;
    grid-template-columns:
      6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
      6.09%;
    grid-column-gap: 1.56%;
    /* grid-template-rows: calc(20vh - 68px; */
    align-content: flex-start;
    justify-content: center;
    position: relative;
  }
  .content-contactus {
    margin: 30px 0 30px;
    grid-column: 2/14;
    display: flex;
    gap: 1rem;
    /* overflow: hidden; */
    position: relative;
    border-radius: 0;
    .img-in-content-contact {
        position: absolute;
        top: -3rem;
        bottom: -4rem;
        left: 3rem;
        width: calc(25%);
        z-index: 100;
        border: 3px solid var(--n-text-6);
        border-radius: 4px;
        box-shadow: var(--n-shadow-1);
        background-color: #ADD0D8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3rem 0.5rem 0;
        img {
            position: relative;
            width: 70%;
            /* height: 80%; */
            margin: auto;
            object-fit: fill;
            animation: phone-anim 1s 1 forwards ease-in-out;
        }
    }
    .formcontant {
        flex-grow: 1;
        /* width: calc(50% - calc(0.5rem + 6px)); */
        height: calc(100% - calc(4rem + 6px));
        z-index: 0;
        border: 3px solid var(--n-text-6);
        border-radius: 4px;
        box-shadow: var(--n-shadow-1);
        background: var(--n-grey-05);
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 0.7rem;
        padding: 3rem 3rem 2rem calc(25% + 6rem);
        .title {
            font-size: 32px;
            line-height: calc(32px * 1.4);
            font-weight: 900;
        }
        .desc {
            font-size: 20px;
            line-height: calc(20px * 1.4);
            font-weight: 500;
            color: var(--n-grey-7)
        }
        .btn {
            margin: 1rem;
            min-width: 40%;
            align-self: flex-end;
            display: flex;
            justify-content: flex-end;
        }
    }
  }
  @keyframes phone-anim {
    0% {
        transform: rotate(0) scale(1)
    }
    10% {
        transform: rotate(-15deg) scale(1)
    }
    20% {
        transform: rotate(15deg) scale(1)
    }
    30% {
        transform: rotate(-15deg) scale(1)
    }
    40% {
        transform: rotate(15deg) scale(1)
    }
    50% {
        transform: rotate(0) scale(1)
    }
    100% {
        transform: rotate(0) scale(1)
    }
  }
  @media screen and (max-width: 1000px) {
    margin-top: 55px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    .container-contactus {
      grid-template-columns: 4.09% repeat(12, calc(calc(88.7% / 12) - calc(17.16% / 12))) 4.09%;
      grid-template-rows: 1fr;
      margin-bottom: 50px;
    }
    .content-contactus {
      flex-direction: column;
      gap: 0.5rem;
      .img-in-content-contact {
        width: calc(60% - calc(1rem + 6px));
        top: -9rem;
        bottom: calc(100% - 14rem);
        left: 20%;
        img {
          width: 75%;
        }
      }
      .formcontant {
        width: calc(100% - calc(2rem + 6px));
        height: calc(100% - calc(2rem + 6px));
        padding: 15rem 1rem 2rem;
        .btn {
            margin: 0rem;
            margin-top: 1rem;
            min-width: 100%;
        }
      }
    }
  }
`;
export default function Section4(props) {
    const ref1 = useRef();
    const moveDown = (e) => {
        console.log(ref1.current)
        window.scrollTo({
        top: ref1.current.offsetHeight + 70,
        behavior: "smooth",
        })
    }
    return (
        <Style className={"contactus-"+Language[lang].dir}>
        <div className="container-contactus">
          <div className="content-contactus">
            <div className="img-in-content-contact">
              <img src={phone1} alt="imgincontentcontact" />
              <div className="socials">
                <NavPodkadehSocial2 />
              </div>
            </div>
            <div className="formcontant">
                <div className="title">
                Getting Started:
                </div>
                <div className="desc">
                Partner with Podkadeh to transform podcast advertising on your platform. Contact us for technical integration details and revenue projections.
                </div>
                <div className="btn">
                    <TextBtn
                        type={"link-inside"}
                        text={"Contact us"}
                        // onclick={sendData}
                        loading={false}
                        disabled={false}
                        color={0}
                        href={"/contactus"}
                    />
                </div>
            </div>
          </div>
        </div>
      </Style>
    )
}