import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IconDown from "../../Assets/Icons/IconDown";
const Style = styled.div`
  grid-column: 2/14;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 81px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 16px;
  cursor: pointer;
  border: none;
  background: var(--primary-1);
  .style-component-question {
    display: flex;
    flex-direction: column;
    margin: 0 40px;
    width: 100%;
    @media (max-width: 1000px) {
      margin:0 20px !important;
    }
    .question {
      font-size: 20px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .answer {
      
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 41px;
      margin-bottom: 25px;
      text-align: justify;
    }
  }
  .rotate-icon-up {
    transform: rotate(180deg);
  }
  .icon-up {
    background: none;
    border: none;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    min-height: 81px !important;
    stroke: #ffffff;
  }
  @media screen and (max-width: 600px) {
    display: flex;
    background: var(--primary-1);
    flex-direction: row;
    justify-content: space-between;
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    border-radius: 0;
    cursor: pointer;
    border: none;
    margin-bottom: 20px !important;
    .style-component-question {
      display: flex !important;
      align-items: center !important;
      text-align: center !important;
      justify-content: center !important;
      margin-right: 12px !important;
      .answer {
        
        font-style: normal;
        font-weight: 400;
        font-size: var(--fs--24-14-14);
        line-height: 24px;
        display: flex !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        text-align: right;
        color: #ffffff;
        margin-bottom: 1rem;
      }
      .question {
        margin: 0 !important;
        
        font-style: normal;
        font-weight: 400;
        font-size: var(--fs--24-14-14);
        line-height: 24px;
        display: flex !important;
        align-items: center !important;
        text-align: center !important;
        justify-content: space-between !important;
        color: #ffffff;
      }
    }
    .rotate-icon-up {
      transform: rotate(180deg);
    }
    .icon-up {
      margin: 7px 12px 6px !important;
      background-color: inherit;
      border: none;
      display: flex !important;
      align-items: center !important;
      text-align: center !important;
      justify-content: center !important;
    }
  }
`;
export default function QuestionComponent({
  title,
  desc,
  ind,
  opened,
  changeOpened,
}) {
  //for open and close tab
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (ind !== opened) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [ind, opened]);

  return (
    <Style
      onClick={() => {
        //for open one tab

        if (!open) {
          changeOpened(ind);
        } else {
          changeOpened(-1);
        }
        //for open one tab
      }}
    >
      <div className="style-component-question">
        <p className="question">
          {title}
          <div className="icon-up">
            {open ? <IconDown className={"rotate-icon-up"} /> : <IconDown />}
          </div>
        </p>
        {open ? (
          //for open and close tab
          <p className="answer"> {desc} </p>
        ) : (
          ""
        )}
      </div>
    </Style>
  );
}
