import React from "react";
import styled from "styled-components";
import MainLayout from "./Layout/MainLayout";
import Section1 from "../Components/DynamicAds/Section1";
import Section3 from "../Components/DynamicAds/Section3";
import Section2 from "../Components/DynamicAds/Section2";
import Section4 from "../Components/DynamicAds/Section4";
const Style = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
`;
export default function DynamicAds(props) {
  return (
    <MainLayout {...props}>
      <Style>
        <Section1/>
        <Section2/>
        <Section3/>
        <Section4/>
      </Style>
    </MainLayout>
  );
}